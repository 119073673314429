import { bankHttpGET, bankHttpPOST, bankHttpPUT } from "../../utils/axios/bank/bankAxiosInstance";
import { httpGET, httpPOST, httpPUT } from "../../utils/axiosInstance";
import { bankProtectedRoute, gameProtectedRoute } from "../../utils/constants";

export const personalBankSignup = async (data) => {
    let url = `${gameProtectedRoute}/bank/personal/signup`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const startupBankSignup = async (data) => {
    let url = `${gameProtectedRoute}/bank/company/signup`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const bankAccountLogin = async (data) => {
    let url = `${gameProtectedRoute}/bank/login`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const bankLogout = async (data) => {
    let url = `${gameProtectedRoute}/bank/logout`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getBankDetails = async () => {
    let url = `${bankProtectedRoute}/bank-details`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getAllBanksDetails = async () => {
    let url = `${gameProtectedRoute}/all-banksdetails`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getUserBankDetails = async () => {
    let url = `${bankProtectedRoute}/accountdetails`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getBankBalance = async () => {
    let url = `${bankProtectedRoute}/balance`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getTransactions = async ({historyType, skip, limit}) => {
    let url = `${bankProtectedRoute}/transactions/${historyType}/${skip}/${limit}`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getUserBankData = async () => {
    let url = `${gameProtectedRoute}/bank/has-bank`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const bankForgetPassword = async (data) => {
    let url = `${gameProtectedRoute}/bank/forgetpassword`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const verifyResetLink = async (data) => {
    let url = `${gameProtectedRoute}/bank/resetlink/verify`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const resetBankPassword = async (data) => {
    let url = `${gameProtectedRoute}/bank/password/reset`
    return await httpPUT(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const resetNewBankPassword = async (data) => {
    let url = `${bankProtectedRoute}/reset-new-password`
    return await bankHttpPUT(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const fetchFixedDeposits = async () => {
    let url = `${bankProtectedRoute}/fd/fixed-deposits`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const openFixedDeposit = async (data) => {
    let url = `${bankProtectedRoute}/open/fixed-deposit`
    return await bankHttpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const closeFixedDeposit = async (data) => {
    let url = `${bankProtectedRoute}/break/fixed-deposit`
    return await bankHttpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

/**
 * Returns wallet balance
 * If user logged into bank account as personal fetch personal waller otherwise startup wallet
 */
export const getWalletBalance = async () => {
    let url = `${bankProtectedRoute}/wallet/balance`
    return await bankHttpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

// export const breakFixedDeposit = async (data) => {
//     let url = `${bankProtectedRoute}/open/fixed-deposit`
//     return await bankHttpPOST(url, data)
//         .then((res) => {
//             return Promise.resolve(res)
//         }).catch(async (err) => {
//             return Promise.reject(err.response)
//         })
// }