import * as React from 'react';
import Box from '@mui/material/Box';
import PlutocratInfoScroll from '../snippets/PlutocratInfoScroll';
import { useContext } from 'react';
import { AppContext } from '../services/AppContext';

export default function PlutocratFooter({role}) {
  const ref = React.useRef(null);
  const { openSideDrawer } = useContext(AppContext);
  return (
    <Box sx={{ pb: 7, }} ref={ref} >
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: "#333333", height: "50px", width: openSideDrawer ? "calc(100% - 135px)" : "calc(100%)", marginLeft: openSideDrawer && "135px", }}>
        <PlutocratInfoScroll role={role} />
      </div>
    </Box>
  );
}

