import { CheckCircle, Favorite, Report, ReportProblem, ThumbUp } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const AlertDialogue = ({setOpen, open, header, message, redirect, type = "alert"}) => {
    const navigate = useNavigate();
    const closeAlertHandler = () => {
        setOpen(false);
        if(redirect){
            navigate(redirect)
        }
    }
    const iconTypes = {
        "alert" : <ReportProblem sx={{color: 'var(--alert-yellow)'}} />,
        "love" : <Favorite sx={{color:'var(--alert-error)'}} />,
        "success": <CheckCircle sx={{color:'var(--alert-success)'}}/>
    }
    return (
        <>
            <Dialog sx={{ fontFamily: "comfortaa", "& .MuiDialog-paper": { marginLeft: "100px", background: "var(--secondary-background)", borderRadius: "var(--least-radius)", border: "1px solid var(--notification-drop-down-background)" } }} open={open}>
                <div className="confirm-wrapper">
                    <div className="alert-icon">{iconTypes[type]}</div>
                    <div className="confirm-header">{header && header}</div>
                    <div className="confirm-message">{message && message}</div>
                    <div className="yes-no">
                        <button onClick={() => closeAlertHandler()} className="pluto-main-button"><ThumbUp />Ok</button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default AlertDialogue;
