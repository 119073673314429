import React, { useContext } from 'react';
import logo from "../../assets/icons/logo.png"
import { AppContext } from '../../services/AppContext';

const PageLoading = ({ loginScreen = false }) => {
    const { openSideDrawer } = useContext(AppContext);
    return (
        <div style={{
            width: loginScreen ? "calc(100%)" : openSideDrawer ? "calc(100% - 200px)" : "calc(100% - 65px)",
            height: loginScreen ? "calc(100vh)" : "calc(100vh - 105px)",
            marginLeft: loginScreen ? "0" : openSideDrawer ? "200px" : "65px",
            borderRadius: loginScreen ? "0" : "20px 0 0 0",
        }} className="page-loading-wrapper">
            <div className="page-loading">
                <img className='main' src={logo} alt="" />
                <img className='shadow' src={logo} alt="" />
            </div>
        </div>
    );
}

export default PageLoading;
