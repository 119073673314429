export const STATUS_CODE = {
    "success": 200,
    "error": 401,
    "forbidden": 403,
    "server_error": 500,
}

export const POST_LIMIT = 20
export const NEWS_LIMIT = 20
export const BOATS_LIMIT = 5
export const ASSET_LIMIT = 20
export const NETWORTH_LIMIT = 20
export const TRANSACTION_HISTORY_LIMIT = 10
export const MAILS_LIMIT = 20
export const BULLETIN_LIMIT = 20
export const PROFILE_MAX_LENGTH = 200
export const gameProtectedRoute = "/game/protected"
export const bankProtectedRoute = "/bank/protected"
export const stockTradeInputs = [
    {
        name: "PlutoBot",
        command: "/view ",
        description: "View the basic stats of your entered stock symbol ( open price, high price, etc. )",
        example: "/view <stock symbol>",
    },
    {
        name: "PlutoBot",
        command: "/detailed ",
        description: "View the advanced stats of your entered stock symbol ( PE-Ratio, 52WeekHigh, etc. )",
        example: "/detailed <stock symbol>",
    },
    {
        name: "PlutoBot",
        command: "/buy ",
        description: "Purchase the stock symbol of your choice in the quantity your prefer",
        example: "/buy <stock symbol> <quantity>",
    },
    {
        name: "PlutoBot",
        command: "/sell ",
        description: "Sell your desired quantity of stocks from your existing portfolio",
        example: "/sell <stock symbol> <quantity>",
    },
    {
        name: "PlutoBot",
        command: "/portfolio ",
        description: "Track the profits/losses in your current portfolio",
        example: "/portfolio",
    },
    {
        name: "PlutoBot",
        command: "/portfolio ",
        description: "Track the profits/losses in your current portfolio of your entered stock symbol",
        example: "/portfolio <stock symbol>",
    },
];
export const commodityTradeInputs = [
    {
        name: "PlutoBot",
        command: "/price ",
        description: "Check gold's current market price and availability from Pluto government",
        example: "/price",
    },
    {
        name: "PlutoBot",
        command: "/buy ",
        description: "Buy your preferred quantity of gold.",
        example: "/buy gold <quantity>",
    },
    {
        name: "PlutoBot",
        command: "/sell ",
        description: "Sell your desired quantity of gold from your portfolio to another user.",
        example: "/sell gold <quantity> <buyer username> <price>",
    },
    {
        name: "PlutoBot",
        command: "/portfolio ",
        description: "Track the profits/losses in your current portfolio",
        example: "/portfolio",
    },
];