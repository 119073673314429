import { Logout, Person } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { removeGameData } from "../../../../utils/removeGameData";
import { AppContext, socket } from "../../../../services/AppContext";
import Confirmation from "../../../Dialogues/Confirmation";
import InstructorProfileDisplay from "./InstructorProfileDisplay";

const InstructorProfileDropdown = ({ imageLoading, image, setProfileDropDown }) => {
    const navigate = useNavigate();
    const [openConfirmModal, setOpenConfirmModal] = useState(false); //confirmation dialogue for deleting
    const user = useSelector((state) => state.user.currentUser);
    const { setAppContextDefaultValues } = useContext(AppContext);
    const pageNavigateHandler = (to) => {
        navigate(to);
        setProfileDropDown(false);
    };
    const handleLogout = async () => {
        let displaytoast = false;
        socket.emit("logout", user._id);
        removeGameData(undefined, displaytoast).then(() => {
            setAppContextDefaultValues();
            navigate("/login");
        });
    };
    const handleConfirmDialogueHandler = () => {
        setOpenConfirmModal(true);
    };
    return (
        <>
            <div className="drop-down-wrapper">
                <div className="upp-arrow"></div>
                <InstructorProfileDisplay imageLoading={imageLoading} userImage={image} />
                <div className="profile-item-divider"></div>
                <div className="profile-items">
                    <div onClick={() => pageNavigateHandler("/instructor/profile")}>
                        <Person /> My Profile
                    </div>
                    <div onClick={() => handleConfirmDialogueHandler()}>
                        <Logout /> Logout
                    </div>
                </div>
            </div>
            <Confirmation message={"Proceed to logout?"} setOpenConfirmModal={setOpenConfirmModal} callback={handleLogout} openConfirmModal={openConfirmModal} />
        </>
    );
};

export default InstructorProfileDropdown;
