import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppContext } from './AppContext';

const AuthRoutes = ({ component: Component, ...props }) => {
      const { setSideBarVisible } = useContext(AppContext);
      const user = useSelector((state) => state?.user?.currentUser);
      const refreshToken = Cookies.get('refresh-token');

      let hasAccess = refreshToken && user; // Determine if the user has access

      useEffect(() => {
            if (!hasAccess) {
                  setSideBarVisible(false);
            }
      }, [setSideBarVisible, hasAccess]);

      return hasAccess ? <Component {...props} /> : <Navigate to="/login" />;
};

export default AuthRoutes;
