import Cookies from "js-cookie"

/**
 * Removes all the tokens related to bank.
 */
export const removeBankCookies = () => {
    Cookies.remove('bank-access-token', { path: '/' })
    Cookies.remove('bank-refresh-token', { path: '/' })
}

export const removeStartupBankCookies = () => {
    Cookies.remove('bank-startup-access-token', { path: '/' })
    Cookies.remove('bank-startup-refresh-token', { path: '/' })
}



/**
 * Removes all the tokens related to the main game.
 */
export const removeGameCookies = () => {
    Cookies.remove('access-token', { path: '/' })
    Cookies.remove('refresh-token', { path: '/' })
}