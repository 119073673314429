/**
 * Creating one instance of axios and then use that instance to make a requests
 */

import axios from "axios";
import { API } from "../../../backend";
import { setupBankInterceptorsTo } from "./setupBankInterceptorsTo";

// instance of axios created
const instance = setupBankInterceptorsTo(
    axios.create({
        baseURL: API,
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: false
    })
)


/**
 * Module to make a post request.
 * @param {*} url post url
 * @param {*} header 
 * @param {*} data that needs to send to backend
 * @param {*} params 
 */
export const bankHttpPOST = (url, data) => {
    return new Promise((resolve, reject) => {
        instance.post(url, data)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    reject(err)
                } else {
                    reject(err)
                }
            })
    })
}


export const bankHttpPUT = (url, data) => {
    return new Promise((resolve, reject) => {
        instance.put(url, data)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    reject(err)
                } else {
                    reject(err)
                }
            })
    })
}

export const bankHttpDELETE = (url) => {
    return new Promise((resolve, reject) => {
        instance.delete(url)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    reject(err)
                } else {
                    reject(err)
                }
            })
    })
}

export const bankHttpPATCH = (url, data) => {
    return new Promise((resolve, reject) => {
        instance.patch(url, data)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    reject(err)
                } else {
                    reject(err)
                }
            })
    })
}


export const bankHttpGET = (url, header) => {
    return new Promise((resolve, reject) => {
        instance.get(url, { headers: header })
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    reject(err)
                } else {
                    reject(err)
                }
            })
    })
}

