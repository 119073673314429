import Cookies from 'js-cookie';
import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";

// If user not logged in redirects to login page
const LoginCheckRoute = ({ component: Component, ...props }) => {
      // const user = useSelector((state) => state?.user?.currentUser);
      let alreadyLoggedIn = false
      const user = useSelector((state) => state?.user?.currentUser);
      // let accessToken = Cookies.get('access-token');
      let refreshToken = Cookies.get('refresh-token');
      if (refreshToken && user) {
            alreadyLoggedIn = true
      }
      return (
            !alreadyLoggedIn ? <Component {...props} /> : <Navigate to="/" />
      );
}

export default LoginCheckRoute