import { Notifications } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNotifications, resetNotifications } from '../../../redux/userRedux';
import { AppContext } from '../../../services/AppContext';
import NotificationBody from './components/NotificationBody';
import notificationAudio from "../../../assets/audio/notification.mp3"

const NotificationDropDown = () => {
    const [notifications, setNotifications] = useState([])
    const { socket, notificationDropDownRef, openNotificationDropDown, setOpenNotificationDropDown } = useContext(AppContext);
    const count = useSelector((state) => state.user.newNotifications);

    const audioRef = useRef(null);
    const dispatch = useDispatch()

    const openNotificationHandler = () => {
        if (!openNotificationDropDown) {
            dispatch(resetNotifications())
        }
        setOpenNotificationDropDown(!openNotificationDropDown)
    }
    
    socket.off("new-notification").on("new-notification", (newNotifications) => {
        if (!openNotificationDropDown) {
            dispatch(addNotifications())
            audioRef?.current?.play();
        }
        setNotifications([newNotifications, ...notifications])
    })


    useEffect(() => {
        socket.off("all-notifications").on("all-notifications", (notifications) => {
            setNotifications(notifications)
        })
    }, [])

    
    return (
        <>
            <div ref={notificationDropDownRef} className="notification-wrap">
                <div className="nav-notification-icon">
                    <Notifications onClick={() => openNotificationHandler()} />
                    <span className="count">{count && count > 0 && count}</span>
                </div>
                {openNotificationDropDown && (
                    <NotificationBody notifications={notifications} setNotifications={setNotifications} />
                )}
            </div>
            <audio ref={audioRef} src={notificationAudio}></audio>
        </>
    );
}

export default NotificationDropDown;
