import { httpGET } from "../../utils/axiosInstance";

let protectedPath = "/game/protected";

export const getStockData = async ({ stockType }) => {
    let url = `${protectedPath}/stock/stocks-data/?stockType=${stockType}`;
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch(async (err) => {
            return Promise.reject(err?.response);
        });
}