import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import GenderAvatar from "../../GenderAvatar";
import GenderBasedAvatar from "../../../data/GenderBasedAvatar";
import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../services/AppContext";
import { getUserProfile } from "../../../MiddleWares/Profile/ProfileApi";
import { getProfileDropDownData } from "../../../MiddleWares/UserList/UserListApi";
import InstructorProfileDropdown from "./components/InstructorProfileDropdown";

const ProfileMenuInstructorDropDown = () => {
  const { openProfileDropDown, setProfileDropDown, profileRef, setProfileImage, setFetchDropDownStartupData } = useContext(AppContext);
  const [image, setImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const [dropDownData, setDropDownData] = useState({});
  const [dropDownDataLoading, setDropDownDataLoading] = useState(true);

  const fetchImageHandler = async () => {
    setImageLoading(true);
    await getUserProfile()
      .then((res) => {
        const blob = new Blob([Int8Array.from(res?.data?.profile?.data?.data)], { type: res?.data?.contentType });
        const image = window.URL.createObjectURL(blob);
        setImage(image);
        setProfileImage(image);
      })
      .catch(() => {
        setImage("");
        setProfileImage("");
      });
    setImageLoading(false);
  };

  const fetchProfileDropDownDataHandler = () => {
    setDropDownDataLoading(true);
    getProfileDropDownData()
      .then((res) => {
        setDropDownData(res?.data?.profileData);
      })
      .finally(() => {
        setDropDownDataLoading(false);
        setFetchDropDownStartupData(false);
      });
  };

  // socket.off("accepted").on("accepted", () => {
  //   fetchProfileDropDownDataHandler()
  // })
  //   useEffect(() => {
  //     if (fetchDropDownStartupData) {
  //       fetchProfileDropDownDataHandler();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [fetchDropDownStartupData]);
  //   useEffect(() => {
  //     fetchImageHandler();
  //     // fetchProfileDropDownDataHandler()
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  return (
    <>
      <div ref={profileRef} className="profile-dropdown-wrapper">
        <div style={{ cursor: "pointer" }} onClick={() => setProfileDropDown(!openProfileDropDown)}>
          {imageLoading ? (
            <div style={{ display: "flex", alignItems: "center", padding: "0.3rem 0" }}>
              <CircularProgress size={20} />
            </div>
          ) : (
            <GenderAvatar
              avatar={image || GenderBasedAvatar[user?.gender?.toLowerCase()]}
              initials={user?.email}
              avatarWidth={30}
            />

          )}
        </div>
        {openProfileDropDown && (
          <Suspense>
            <InstructorProfileDropdown imageLoading={imageLoading} image={image} />

          </Suspense>
        )}
      </div>
    </>
  );
};

export default ProfileMenuInstructorDropDown;
