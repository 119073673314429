import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import PlutocratDrawerItem from "../snippets/PlutocratDrawerItem";
import PlutocratFooter from "./PlutocratFooter";
import { useLocation, useNavigate } from "react-router-dom";
import PlutocratAppBar from "./AppBar/PlutocratAppBar.jsx";
import { AppContext } from "../services/AppContext.js";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewCompanyMails, addNewMails, addPersonalMessage, resetCompanyMails, resetMails } from "../redux/userRedux";
import { useRef } from "react";
import notificationAudio from "../assets/audio/mail.mp3";
import { Modal } from "@mui/material";
import StartupSuccessInfo from "./StartupSuccessInfo";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxShodow: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  boxShodow: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  boxShodow: "none",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PlutocratBackdrop() {
  const navigate = useNavigate();
  // const [selected, setSelected] = useState("bank");
  // const [open, setOpen] = useState(true);
  const [home, setHome] = useState(false);
  // const [profile, setProfile] = useState(false);
  const [bank, setBank] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [market, setMarket] = useState(false);
  const [assets, setAssets] = useState(false);
  const [news, setNews] = useState(false);
  const [chat, setChat] = useState(false);
  const [boat, setBoat] = useState(false);
  const [leaderboard, setLeaderboard] = useState(false);
  const defaultColor = "#333333";
  const selectedColor = "#0f0015";
  const { openSideDrawer, setOpenSideDrawer, socket, accounts, currentAccount, openSuccessStartupInfo, setOpenSuccessStartupInfo } = useContext(AppContext);

  const count = useSelector((state) => {
    let c = 0;
    if (!state.user.newCompanyMails && !state.user.newMails) {
      c = undefined;
    } else {
      if (state.user.newMails) {
        c += state.user.newMails;
      }
      if (state.user.newCompanyMails) {
        c += state.user.newCompanyMails;
      }
    }
    return c;
  });
  const personalMessageCount = useSelector((state) => {
    let count = 0;
    if (!state.user.personalMessage) {
      count = undefined;
    } else {
      if (state.user.personalMessage) {
        count += state.user.personalMessage;
      }
    }
    return count;
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const audioRef = useRef(null);

  const resetMailsNotificationHandler = () => {
    if (currentAccount === accounts?.companyEmail) {
      dispatch(resetCompanyMails());
    } else if (currentAccount === accounts?.gameEmail) {
      dispatch(resetMails());
    }
  };

  // const resetPersonalMessageHandler = () => {
  //   dispatch(resetPersonalMessage());
  // };

  socket.off("new-inbox-mail").on("new-inbox-mail", () => {
    if (!communication) {
      dispatch(addNewMails());
      audioRef?.current?.play();
    }
  });

  socket.off("new-company-inbox-mail").on("new-company-inbox-mail", () => {
    if (!communication) {
      dispatch(addNewCompanyMails());
      // dispatch(addNewMails())
      audioRef?.current?.play();
    }
  });
  socket.off("new-personal-message").on("new-personal-message", () => {
    if (!chat) {
      dispatch(addPersonalMessage());
      audioRef?.current?.play();
    }
  });
  socket.off("personal-message-count").on("personal-message-count", (count) => {
    dispatch(addPersonalMessage(count, "home"));
  });
  socket.off("bankruptcy-refresh").on("bankruptcy-refresh", () => {
    window.location.reload();
  });

  socket.off("display-congratulation-info").on("display-congratulation-info", () => {
    setOpenSuccessStartupInfo(true)
  })
  const currentTab = () => {
    select();
    let path = location.pathname;
    if (path === "/") {
      setHome(true);
    } else if (path.includes("/bank/")) {
      setBank(true);
    } else if (path === "/market") {
      setMarket(true);
    } else if (path.includes("/leaderboard")) {
      setLeaderboard(true);
    } else if (path.includes("/boats")) {
      setBoat(true);
    } else if (path.includes("/mails")) {
      setCommunication(true);
    } else if (path === "/new/marketing") {
      setMarketing(true);
    } else if (path.includes("/news")) {
      setNews(true);
    } else if (path.includes("/assets")) {
      setAssets(true);
    } else if (path === "/chat") {
      setChat(true);
    }
  };

  useEffect(() => {
    currentTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const autoCloseDrawer = () => {
    setTimeout(() => {
      setOpenSideDrawer(false);
    }, 3000);
  };

  const select = () => {
    setHome(false);
    setCommunication(false);
    setBank(false);
    setNews(false);
    setAssets(false);
    setMarket(false);
    setMarketing(false);
    setLeaderboard(false);
    setChat(false);
    setBoat(false);
  };

  React.useEffect(() => {
    autoCloseDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "flex", position: "relative", zIndex: "1000", background: defaultColor }}>
        <PlutocratAppBar />
        <Drawer
          variant="permanent"
          open={openSideDrawer}
          PaperProps={{
            sx: {
              backgroundColor: defaultColor,
              border: "none",
            },
          }}
          sx={{ zIndex: 100, position: "relative" }}
        >
          <Toolbar />
          <List style={{ paddingLeft: 10, transition: "all 0.3s ease" }}>
            <div
              onClick={(e) => {
                select();
                setHome(true);
                navigate("/");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem id="home" defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"Home"} selected={home} />
            </div>

            <div
              id="bank"
              onClick={(e) => {
                select();
                setBank(true);
                navigate("/bank/account");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"Bank"} selected={bank} />
            </div>
            <div
              id="mails"
              style={{ position: "relative" }}
              onClick={(e) => {
                select();
                resetMailsNotificationHandler();
                setCommunication(true);
                navigate("/mails");
                e.stopPropagation();
              }}
            >
              {!communication && count && count > 0 && <div className="count-mails">{count}</div>}
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"mails"} icon={"Mails"} selected={communication} />
            </div>
            <div
              id="marketing"
              onClick={(e) => {
                select();
                setMarketing(true);
                navigate("/new/marketing");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"marketing"} icon={"Marketing"} selected={marketing} />
            </div>
            <div
              id="market-place"
              onClick={(e) => {
                select();

                setMarket(true);
                navigate("/market");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"Market Place"} selected={market} />
            </div>
            <div
              id="boats"
              onClick={(e) => {
                select();
                setBoat(true);
                navigate("/boats/list");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"boats"} icon={"Boats"} selected={boat} />
            </div>

            <div
              id="assets"
              onClick={(e) => {
                select();

                setAssets(true);
                navigate("/assets");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"assets"} icon={"Assets"} selected={assets} />
            </div>
            <div
              id="news"
              onClick={(e) => {
                select();

                setNews(true);
                navigate("/news");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"news"} icon={"News"} selected={news} />
            </div>
            <div
              id="chat"
              onClick={(e) => {
                select();
                navigate("/chat");
                setChat(true);
                e.stopPropagation();
                // resetPersonalMessageHandler();
              }}
            >
              {!chat && personalMessageCount && personalMessageCount > 0 && <div className="count-mails count-message">{personalMessageCount}</div>}
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"chat"} icon={"Chat"} selected={chat} />
            </div>
            <div
              id="leaderboard"
              onClick={(e) => {
                select();
                setLeaderboard(true);
                navigate("/leaderboard");
                setLeaderboard(true);
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"Leaderboard"} selected={leaderboard} />
            </div>
          </List>
        </Drawer>
        <PlutocratFooter role={"user"} />
        <audio ref={audioRef} src={notificationAudio}></audio>
      </div>
      <Modal
        open={openSuccessStartupInfo}
        // onClose={() => {
        //   setOpenSuccessStartupInfo(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ width: "65%", height: "70%" }} className="modal-wrapper-market startup-success-display-wrapper">
          <StartupSuccessInfo setOpenSuccessStartupInfo={setOpenSuccessStartupInfo} />
        </div>
      </Modal>
    </>
  );
}
