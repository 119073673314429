import { Alert, Slide, Snackbar } from "@mui/material";
import React from "react";
import TokenIcon from "@mui/icons-material/Token";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const SnackEvent = ({ message, open, setOpen, severity="error", time=15000, snackMargin = true }) => {
  return (
    <Snackbar
    TransitionComponent={TransitionUp}
    sx={{
        zIndex: 20000,
        marginBottom: "1.7rem",
        marginLeft: snackMargin && "2rem",
    }}
      open={open}
      autoHideDuration={time}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setOpen(false);
        }}
        icon={<TokenIcon />}
        severity= {severity}
        sx={{ width: "100%", padding: "0.3rem 1rem", borderRadius: "var(--least-radius)", }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackEvent;
