import { httpGET } from "../../utils/axiosInstance";


export const getUserNetworth = async() => {
    let url = `/game/protected/user/networth`
   
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
                return Promise.reject(err.response)
        })
}



//TODO: remove above modules
export const getNetworths = async( mainLeadrboardType, type, skip, limit) => {
    let url = `/game/protected/leaderboard/${mainLeadrboardType}/networth/${type}/${skip}/${limit}`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
                return Promise.reject(err.response)
        })
}
