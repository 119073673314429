import { AutoAwesome } from '@mui/icons-material';
import React from 'react';

const StartupSuccessInfo = ({ setOpenSuccessStartupInfo }) => {
    return (
        <>
            <div className='startup-success-wrapper-header'>
                <span className='startup-congrats'>CONGRATULATIONS!<AutoAwesome /></span>
                <span className='startup-congrats-message'>Your startup registration is done successfully. <br />Your entrepreneurial journey begins here!</span>
            </div>
            <span className="common-result" style={{lineHeight: "1.3rem"}}>You can check your startup details in the profile page. Click on the profile icon on the top left, and select "My Profile" from the dropdown options. This will navigate you to the profile page. Click on the "Business" tab to check your startup details.</span>
            <span className='startup-what-to-do'>What to do next?</span>
            <div className='startup-success-wrapper-body'>
                <li>Read and complete the missions listed on the homepage under the "Missions" tab.</li>
                <li>Register a bank account for your startup, using the same procedure followed while registering a personal account.</li>
                <li>Do some market research in the news page.</li>
                <li>Buy a warehouse to store your inventory!</li>
                <li>Design your first boat, manufacture and transport it to your warehouse!</li>
                <li>Promote your boat by launching marketing campaigns to increase your sales.</li>
            </div>
            <div className='startup-success-wrapper-footer'>
                {/* <span className='common-header'>Note: You can open this information whenever you choose. To open this information, simply navigate to the profile dropdown menu and select "What to do next?" from the dropdown options.</span> */}
                <button className='pluto-main-button' onClick={() => setOpenSuccessStartupInfo(false)}>UNDERSTOOD</button>
            </div>
        </>
    );
}

export default StartupSuccessInfo;
