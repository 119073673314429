export const HOME = "/";
export const HOME_DASHBOARD = "/new/home";
export const PROFILE = "/profile";
export const COMMUNICATION = "/mails";
export const COMMUNICATION_EMAIL_ID = "/mails/:emailID";
export const LEADER_BOARD = "/leaderboard";
export const NEWS = "/news";
export const ASSETS = "/assets";
export const ASSETS_ROUTES = "/assets/*";
export const MARKETING = "/marketing";
export const NEW_MARKETING = "/new/marketing"
export const MARKETING_HISTORY = "/marketing/history"
export const PLUTOLINK = "/plutolink";
export const GAME_LOGIN = "/login";
export const GAME_RESET_PASSWORD = "/reset-password/:token";
export const STARTUP_ROUTES = "/startup/*";
export const BANK_ROUTES = "/bank/*";
export const MARKET = "/market";
export const MARKET_ROUTES = "/market/*";
export const BOATS_ROUTES = "/boats/*";
export const NOT_FOUND = "*";
export const INTRO = "/intro";
export const USERS_LIST = "/userslist";
export const ORDERS = "/orders";
export const CHAT = "/chat";
export const MANUFACTURER = "/market/manufacturer/:manufacturerID"
export const CHAT_GPT = "/chat-gpt"

//instructor screens
export const ADD_STUDENTS = '/instructor/add-students'
export const VIEW_CLASSES = '/instructor/view-students'
export const STUDENT_DETAILS = "/instructor/students-details"
export const EACH_STUDENT = '/instructor/view-students/:studentID'
export const PROFILE_PAGE = '/instructor/profile'
