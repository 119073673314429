import maleAvatar from "../assets/leaderboard/male-avatar.png";
import femaleAvatar from "../assets/leaderboard/female-avatar.png";
import transAvatar from "../assets/leaderboard/trans-avatar.png";
import company from "../assets/leaderboard/company.svg";

const GenderBasedAvatar = {
    "male": maleAvatar,
    "female" : femaleAvatar,
    "other" : transAvatar,
    "company": company
}

export default GenderBasedAvatar;
