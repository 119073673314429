import { Typography } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CampaignIcon from "@mui/icons-material/Campaign";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { DisplaySettings, Email, Feed, Forum, Groups, PersonAdd, Sailing, Savings } from "@mui/icons-material";

function PlutocratDrawerItem({ ...props }) {
  const selectedIconStyle = {
    minWidth: 0,
    transition: "all 0.3s ease",
    justifyContent: "center",
    borderRadius: "10px 0px 0px 10px",
    margin: props.selected && props.open === false ? "5px" : 0,
    paddingLeft: props.open ? "8px" : "2px",
    width: 28,
    height: 28,
    color: props.selected ? "#7e0fc4" : "white",
    mr: props.open ? 3 : "auto",
  };

  const icons = {
    Home: <HomeIcon sx={selectedIconStyle} />,
    Profile: <AccountCircleIcon sx={selectedIconStyle} />,
    News: <Feed sx={selectedIconStyle} />,
    Assets: <Savings sx={selectedIconStyle} />,
    Bank: <AccountBalanceIcon sx={selectedIconStyle} />,
    Mails: <Email sx={selectedIconStyle} />,
    Marketing: <CampaignIcon sx={selectedIconStyle} />,
    "Market Place": <StorefrontIcon sx={selectedIconStyle} />,
    Leaderboard: <LeaderboardIcon sx={selectedIconStyle} />,
    Chat: <Forum sx={selectedIconStyle} />,
    Boats: <Sailing sx={selectedIconStyle} />,
    "View Students": <Groups sx={selectedIconStyle} />,
    "Add Students": <PersonAdd sx={selectedIconStyle} />,
    "Students details": <DisplaySettings sx={selectedIconStyle} />,
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: props.selected ? props.selectedColor : props.defaultColor,
          boxShadow: "none",
          transition: "all 0.3s ease",
          fontFamily: "comfortaa",
        }}
      >
        <div
          style={{
            height: 15,
            borderRadius: "0px 0px 15px 0px",
            backgroundColor: props.defaultColor,
            display: props.selected ? "" : "none",
            transition: "all 0.3s ease",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "coloumn",
          alignItems: "center",
          padding: 5,
          borderRadius: "10px 0px 0px 10px",
          backgroundColor: props.selected ? props.selectedColor : props.defaultColor,
          transition: "all 0.3s ease",
          cursor: "pointer",
        }}
      >
        {icons[props.icon]}
        <Typography
          sx={{
            opacity: props.open ? 1 : 0,
            color: "white",
            borderRadius: "10px 0px 0px 10px",
            margin: "8px 0px",
            fontFamily: "comfortaa",
            fontSize: "0.9rem",
          }}
        >
          {props.icon}
        </Typography>
      </div>
      <div style={{ backgroundColor: props.selectedColor }}>
        <div
          style={{
            height: 15,
            borderRadius: props.selected ? "0px 15px 0px 0px" : "0px 0px 0px 0px",
            backgroundColor: props.defaultColor,
            display: props.selected ? "" : "none",
          }}
        ></div>
      </div>
    </div>
  );
}

export default PlutocratDrawerItem;
