import { httpPOST, httpPUT } from "../../utils/axiosInstance";

export const gameLogout = async (data) => {
    let url = `/auth/game/logout`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err?.response)
        })
}

export const gameForgetPasswordLink = async (data) => {
    let url = `/auth/forget-password`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err?.response)
        })
}

export const verifyGameResetLink = async (data) => {
    let url = `/auth/reset-link/verify`
    return await httpPOST(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err?.response)
        })
}


export const resetGamePassword = async (data) => {
    let url = `/auth/password/reset`
    return await httpPUT(url, data)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err?.response)
        })
}
