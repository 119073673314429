import React, { useState } from 'react';
import GenderBasedAvatar from "../../../../data/GenderBasedAvatar";
import { useSelector } from 'react-redux';
import { CircularProgress, Modal, Tooltip } from '@mui/material';
import GenderAvatar from '../../../GenderAvatar';


const InstructorProfileDisplay = ({ imageLoading, userImage }) => {

    const user = useSelector((state) => state.user.currentUser);

    return (
        <>
            <div className="profile-display">
                {imageLoading ?
                    <div style={{ display: "flex", alignItems: "center", padding: "0.3rem 0" }}><CircularProgress size={20} /></div> :
                    <GenderAvatar
                        avatar={userImage || GenderBasedAvatar[user?.gender?.toLowerCase()]}
                        avatarWidth={30}
                        initials={user?.email}
                    />

                }
            </div >
        </>
    );
}

export default InstructorProfileDisplay;
