import { httpGET } from "../../utils/axiosInstance";

/**
 * fetch the recent players information who buyed assets from whom
 * @param {*} accessToken 
 * @param {*} refreshToken 
 * @param {*} dispatch 
 * @returns 
 */
export const getBulletinNews = async ({skip, limit}) => {
    let url = `/game/protected/asset/bulletin/news/${skip}/${limit}`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}


export const getFooterBoatNews = async () => {
    let url = `/game/protected/asset/boats/news`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}