import { httpPUT } from "../../utils/axiosInstance";

const protectedPath = "auth/game/protected";
export const introUpdate = async (introPage) => {
    let url = `${protectedPath}/user/intro/${introPage}`
    return await httpPUT(url)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch(async (err) => {
            return Promise.reject(err?.response);
        });
}

export const userDetailsUpdate = async (role, data) => {
    const url = `${protectedPath}/${role}/`
    return await httpPUT(url, data)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch(async (err) => {
            return Promise.reject(err?.response);
        });
}