import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      console.log(action.payload);
      state.currentUser = action.payload;
    },
    clearGameLoginDetails: (state) => {
      state.currentUser = null
    },
    updateRedux: (state, action) => {
      state.currentUser = action.payload.user;
    },
    addNotifications: (state) => {
      if (state.newNotifications) {
        state.newNotifications += 1;
      } else {
        state.newNotifications = 1;
      }
    },
    resetNotifications: (state) => {
      delete state.newNotifications
    },
    addNewMails: (state) => {
      if (state.newMails) {
        state.newMails += 1;
      } else {
        state.newMails = 1;
      }
    },
    resetMails: (state) => {
      delete state.newMails
    },
    addNewCompanyMails: (state) => {
      if (state.newCompanyMails) {
        state.newCompanyMails += 1;
      } else {
        state.newCompanyMails = 1;
      }
    },
    resetCompanyMails: (state) => {
      delete state.newCompanyMails
    },
    addPersonalMessage: (state, action) => {
      if (state.personalMessage && !action?.payload) {
        if (state.personalMessage) {
          state.personalMessage += 1;
        } else {
          state.personalMessage = 1
        }
      } else {
        state.personalMessage = (action?.payload ? action?.payload : 1);
      }
    },
    decreasePersonalMessageCount: (state, action) => {
      if (state.personalMessage) {
        state.personalMessage -= (action?.payload || 0);
      }
    },
    resetPersonalMessage: (state) => {
      delete state.personalMessage
    }
  },
});

export const { loginSuccess, clearGameLoginDetails, updateRedux, addNotifications, resetNotifications, addNewMails, resetMails, addNewCompanyMails, resetCompanyMails, addPersonalMessage, resetPersonalMessage, decreasePersonalMessageCount } = userSlice.actions;
export default userSlice.reducer;
