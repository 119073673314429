import { CircularProgress, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { FirstLoginTextField, defaultInputPropsStyle } from '../../Themes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import "../../styles/_first_login.scss"
import useActivateToast from '../../customHooks/toast/useActivateToast';
import { userDetailsUpdate } from '../../MiddleWares/User/userApi';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/userRedux';

const FirstLogin = ({ user }) => {
    const { activateSneak } = useActivateToast();
    const dispatch = useDispatch();

    const [userDetails, setUserDetails] = useState({
        name: "",
        username: "",
        phone: "",
        age: "",
        gender: "",
        password: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [nameError, setNameError] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [genderError, setGenderError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassError, setConfirmPassError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleInput = (name, errorEvent) => (event) => {
        errorEvent(false);
        setUserDetails({ ...userDetails, [name]: event.target.value });
    };


    const setAllValidationsFalse = () => {
        setNameError(false);
        setGenderError(false);
        setPhoneNumberError(false);
        setUsernameError(false);
        setPasswordError(false);
        setConfirmPassError(false);
        setAgeError(false);
    };

    const checkIfAllFieldsFilled = () => {
        const requiredFields = ['name', 'age', 'phone', 'gender', 'username', 'password', 'confirmPassword'];
        let isFieldsEmpty = false;

        requiredFields.forEach((field) => {
            if (!userDetails[field]) {
                switch (field) {
                    case 'name':
                        setNameError(true);
                        break;
                    case 'age':
                        setAgeError(true);
                        break;
                    case 'phone':
                        setPhoneNumberError(true);
                        break;
                    case 'gender':
                        setGenderError(true);
                        break;
                    case 'username':
                        setUsernameError(true);
                        break;
                    case 'password':
                        setPasswordError(true);
                        break;
                    case 'confirmPassword':
                        setConfirmPassError(true);
                        break;
                    default:
                        break;
                }
                isFieldsEmpty = true;
            }
        });

        return isFieldsEmpty;
    };

    const checkPassword = () => {
        const { password, newPassword, confirmPassword } = userDetails;

        if (!password) {
            setPasswordError(true)
            activateSneak("Please fill Password", "error");
            return true;
        }

        if (!newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/)) {
            setConfirmPassError(true);
            activateSneak("Password should contain at least one uppercase, lowercase, number, and special character", "error");
            return true;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPassError(true);
            activateSneak("New password and confirm password should be the same", "error");
            return true;
        }

        if (password === newPassword) {
            setPasswordError(true)
            setConfirmPassError(true);
            activateSneak("Password and new password cannot be the same.", "error");
            return true
        }

        return false;
    };

    const checkAgePhoneNumber = () => {
        const { age, phone } = userDetails
        if (parseInt(age) < 18) {
            activateSneak("Your age must be greater than 17!", "error");
            return true;
        } else if (parseInt(age) > 100) {
            activateSneak("Your age must be at most 100!", "error");
            return true;
        } else if (!phone.match(/^[1-9]\d{9}$/)) {
            // activateSneak("Only valid phone numbers, The phone number must consist of exactly 10 numerical digits, with no other characters or symbols included.", "error");
            activateSneak("Please enter a valid 10-digit phone number with no other characters or symbols.", "error");
            setPhoneNumberError(true);
            return true;
        }
        return false;
    };

    const checkValidations = () => {
        setAllValidationsFalse();

        if (checkIfAllFieldsFilled()) {
            activateSneak("Fill all the required fields.", "error");
            return true;
        }
        if (!userDetails.name.trim().match(/^[A-Za-z]+(?: [A-Za-z]+)*$/)) {
            setNameError(true);
            activateSneak("The name should only have alphabetic characters, with a minimum length of 3 characters and a maximum length of 40 characters.", "error");
            return true;
        }

        if (checkAgePhoneNumber()) {
            return true;
        }
        if (!userDetails.gender) {
            setGenderError(true);
            activateSneak("Please select a gender", "error");
            return true;
        }
        if (!userDetails.username.trim().match(/^[A-Za-z][A-Za-z0-9]{5,14}$/)) {
            setUsernameError(true);
            activateSneak("The username must consist only of alphanumeric characters and be between 6 and 15 characters in length.", "error");
            return true;
        }
        if (checkPassword()) {
            return true;
        }
        return false;
    };

    const handleSendOTP = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!checkValidations()) {
            await userDetailsUpdate(user.role, userDetails).then((resp) => {
                const { data } = resp
                activateSneak(data.message, "success")
                dispatch(loginSuccess(data.user))
                window.location.reload()
            }).catch((err) => {
                const { data } = err
                activateSneak(data.message)
                if (data?.nameError) setNameError(true)
                if (data?.newPasswordError) setConfirmPassError(true)
                if (data?.passwordError) setPasswordError(true)
                if (data?.usernameError) setUsernameError(true)
                if (data?.phoneNumberError) setPhoneNumberError(true)

            })
            setLoading(false);
            // console.log(data);
        }
    };

    return (
        <div className='first-login-wrapper' >
            <div className="header">Fill The Form</div>
            <form className="form-container" onSubmit={handleSendOTP}>
                <FirstLoginTextField
                    type="text"
                    required={true}
                    autoComplete="off"
                    inputProps={{
                        maxLength: 40,
                    }}
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    value={userDetails.name}
                    error={nameError}
                    label="NAME"
                    maxWidth={true}
                    onChange={handleInput("name", setNameError)}
                />
                <FirstLoginTextField
                    type="text"
                    required={true}
                    autoComplete="off"
                    inputProps={{
                        maxLength: 40,
                    }}
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    value={userDetails.username}
                    error={usernameError}
                    label="USERNAME"
                    maxWidth={true}
                    onChange={handleInput("username", setUsernameError)}
                />
                <FirstLoginTextField
                    autoComplete="off"
                    type="number"
                    required={true}
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    value={userDetails.phone}
                    error={phoneNumberError}
                    label="PHONE NUMBER"
                    onChange={handleInput("phone", setPhoneNumberError)}
                    maxWidth={true}
                    sx={{ flex: 0, minWidth: "150px" }}
                />
                <FirstLoginTextField
                    autoComplete="off"
                    type="number"
                    required={true}
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    value={userDetails.age}
                    error={ageError}
                    onChange={handleInput("age", setAgeError)}
                    label="AGE"
                    maxWidth={true}
                    sx={{ flex: 0, minWidth: "150px" }}
                />
                <div className={`gender-selection ${genderError ? 'error' : ''}`}>
                    <div class="radio-wrapper">
                        <input
                            class="gender-radio-buttons"
                            id="male"
                            value="male"
                            name="gender"
                            type="radio"
                            onChange={handleInput("gender", setGenderError)}

                        />
                        <label class="genderlabel malebutton" for="male">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 50 90"
                                class="smallsvg malesmallsvg"
                            >
                                <circle
                                    stroke-width="6"
                                    stroke="#76E3FE"
                                    r="22"
                                    cy="25"
                                    cx="25"
                                ></circle>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#76E3FE"
                                    d="M25 47L25 87"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#76E3FE"
                                    d="M25 86.6958L38.6958 73"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#76E3FE"
                                    d="M11 73L24.6958 86.6958"
                                ></path></svg
                            >Male
                        </label>

                        <input
                            class="gender-radio-buttons"
                            id="female"
                            value="female"
                            name="gender"
                            type="radio"
                            onChange={handleInput("gender", setGenderError)}
                        />
                        <label class="genderlabel femalebutton" for="female">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 50 90"
                                class="smallsvg"
                            >
                                <circle
                                    stroke-width="6"
                                    stroke="#F57CB3"
                                    r="22"
                                    cy="25"
                                    cx="25"
                                ></circle>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#F57CB3"
                                    d="M25 47L25 87"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#F57CB3"
                                    d="M12 73H38"
                                ></path></svg
                            >Female
                        </label>

                        <input
                            class="gender-radio-buttons"
                            id="other"
                            value="other"
                            name="gender"
                            type="radio"
                            onChange={handleInput("gender", setGenderError)}
                        />
                        <label class="genderlabel otherbutton" for="other">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 78 75"
                                class="smallsvg other-gender"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M73.4657 16.6983L48.2159 16.6984L19.9816 58.0001L2.99911 58"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M73.1641 16.698L59.4705 2.99992"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M59.4648 30.696L73.1629 17.0024"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M74.022 57.8121L51.1697 57.8121L19.9997 16.9999L3 17"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M73.748 57.8123L61.3547 71.51"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="6"
                                    stroke="#9B4AED"
                                    d="M61.3496 43.8147L73.747 57.5079"
                                ></path>
                            </svg>

                            Other
                        </label>
                    </div>
                    {genderError && (
                        <div className="d-fl just-cont-cen">
                            <FormHelperText error={true}>Please select a gender</FormHelperText>
                        </div>
                    )}

                </div>
                <FirstLoginTextField
                    type="password"
                    autoComplete="off"
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    label="PASSWORD"
                    maxWidth={true}
                    value={userDetails.password}
                    error={passwordError}
                    onChange={handleInput("password", setPasswordError)}
                />
                <FirstLoginTextField
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    InputProps={{
                        style: defaultInputPropsStyle,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton sx={{ color: "white" }} aria-label="toggle password visibility" edge="end" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="NEW PASSWORD"
                    maxWidth={true}
                    value={userDetails.newPassword}
                    error={confirmPassError}
                    onChange={handleInput("newPassword", setConfirmPassError)}
                />
                <FirstLoginTextField
                    type="password"
                    autoComplete="off"
                    InputProps={{
                        style: defaultInputPropsStyle,
                    }}
                    value={userDetails.confirmPassword}
                    error={confirmPassError}
                    onChange={handleInput("confirmPassword", setConfirmPassError)}
                    label="CONFIRM NEW PASSWORD"
                    maxWidth={true}
                />
                <div className='pluto-main-button' type="submit" style={{ borderRadius: "7px", marginTop: "0.5rem", marginBottom: "1rem" }} onClick={handleSendOTP} >
                    {loading ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CircularProgress size={15} />
                        </div>
                    ) : (
                        "Update Details"
                    )}
                </div>
            </form>
        </div>
    );
}

export default FirstLogin;
