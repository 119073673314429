import React, { useEffect, useState } from "react";
import Base from "../../comp/Base";
import { getUserDetails } from "../../MiddleWares/UserList/UserListApi";
import { socket } from "../../services/AppContext";
import UsersDetails from "./UsersDetails";
import { useLocation, useNavigate } from "react-router";
import EllipsisLoading from "../../comp/Loading/EllipsisLoading";

const UsersList = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [usersType, setUsersType] = useState("all");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => { }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      navigate("/userslist");
      event.preventDefault();
      // event.returnValue = '';
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchUsersDetails = async () => {
    setLoading(true);
    setUserDetails([]);
    await getUserDetails(usersType)
      .then((res) => {
        setUserDetails(res?.data?.usersDetails);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  socket.off("online-users-update").on("online-users-update", (data) => {
    if (!data) return;
    if (usersType === "online") {
      setUserDetails((prevUsers) => {
        if (!prevUsers.some((onlineUsers) => onlineUsers?._id === data?._id)) {
          return [...prevUsers, data];
        }
        return prevUsers;
      });
    }
  });
  socket.off("online-user-disconnect").on("online-user-disconnect", (userId) => {
    if (!userId) return;
    if (usersType === "online") {
      setUserDetails((prevUsers) => {
        return prevUsers.filter((onlineUsers) => onlineUsers?._id !== userId);
      });
    }
  });

  socket.off("onlineUsers").on("onlineUsers", (users) => {
    if (users?.length > 0) {
      setUserDetails(users);
    }
  });

  useEffect(() => {
    fetchUsersDetails();
    return () => {
      setUserDetails([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersType]);

  return (
    <>
      <Base>
        <div className="users-list-wrapper">
          <div className="users-list-wrapper-header">
            <span className="users-list-page-title">Players</span>
          </div>
          <div className="users-list-wrapper-body-top">
            <div className="select">
              <button onClick={(e) => setUsersType("all")} className={`users-type-btn all ${usersType === "all" && "active"}`}>
                All Users
              </button>
              <button onClick={(e) => setUsersType("online")} className={`users-type-btn online ${usersType === "online" && "active"}`}>
                Active Users
              </button>
            </div>
            {/* <div className="search-new-mail-btn">
                            <div className="common-search-wrapper">
                                <div className="common-search-input-button">
                                    <SearchIcon />
                                    <input type="text" placeholder="Search people,emails, etc"></input>
                                    <Tooltip title="Clear Search" arrow >
                                        <Close sx={{ cursor: "pointer", fontSize: '1rem' }} />
                                    </Tooltip>
                                </div>
                                <button className="pluto-main-button common-search-button">Search</button>
                            </div>
                        </div> */}
          </div>
          <div className="users-list-wrapper-body">
            {loading ? (
              <EllipsisLoading />
            ) : (
              <>
                {userDetails?.length > 0 ? (
                  userDetails.map((user) => <UsersDetails user={user} key={user?._id} />)
                ) : (
                  <>
                    <div className="no-content">
                      <span>No Users</span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Base>
    </>
  );
};

export default UsersList;
