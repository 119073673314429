import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Base from "../../comp/Base.js";
import PageLoading from "../../comp/Loading/PageLoading.jsx";
import PageNotFound from "../../pages/PageNotFound.jsx";
const StartupRegister = lazy(() => import("./StartupRegister.jsx"))

const StartupScreenRoutes = () => {
    const path = {
        register: `register`,
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ""; // this is required for Chrome
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <Suspense fallback={<Base><PageLoading /></Base>}>
                <Routes>
                    <Route exact path={path.register} element={<StartupRegister />} />
                    <Route exact path="*" element={<PageNotFound />} />
                    {/* <Route path={path.auth} render={() => <Redirect to={path.school} />} /> */}
                </Routes>
            </Suspense>
        </>
    );
};

export default StartupScreenRoutes;