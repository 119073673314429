
import { io } from "socket.io-client"
import { createContext, useRef, useState } from "react"
import { SOCKET_URL } from "../backend";

export const socket = io(SOCKET_URL);

export const AppContext = createContext();
export const AuthProvider = ({ children }) => {
    const [openSideDrawer, setOpenSideDrawer] = useState(true)
    const [openProfileDropDown, setProfileDropDown] = useState(false)
    const [openNotificationDropDown, setOpenNotificationDropDown] = useState(false)
    const [sideBarVisible, setSideBarVisible] = useState(false)
    const profileRef = useRef();
    const stocksRef = useRef();
    // const bankRef = useRef();
    const botRef = useRef();
    const notificationDropDownRef = useRef();

    // to show warnings, success, error messages
    const [mainSnackEvent, setMainSnackEvent] = useState(false)
    const [mainSnackMessage, setMainSnackMessage] = useState("")
    const [mainSnackSeverity, setMainSnackSeverity] = useState("error")
    const [mainSnackMargin, setMainSnackMargin] = useState(true)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState("");
    const [alertType, setAlertType] = useState("alert");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertNavigateTo, setAlertNavigateTo] = useState(null);
    const [profileImage, setProfileImage] = useState("");
    const [openStocks, setOpenStocks] = useState(false);
    const [openSuccessStartupInfo, setOpenSuccessStartupInfo] = useState(false);
    const [fetchDropDownStartupData, setFetchDropDownStartupData] = useState(true);
    const [noStartup, setNoStartup] = useState(false);

    // const [openDropDown, setOpenDropDown] = useState(false);
    const [botOpen, setBotOpen] = useState(false);

    //mails states
    const [currentAccount, setCurrentAccount] = useState("");
    const [accounts, setAccounts] = useState(null);
    const [refetchAccounts, setRefetchAccounts] = useState(true)

    // widgets

    const [refetchWidgets, setRefetchWidgets] = useState(true)
    const [widgets, setWidgets] = useState([])

    const [openRedirectTimer, setOpenRedirectTimer] = useState(false)
    const [redirectMessage, setRedirectMessage] = useState("")
    const [redirectToWhere, setRedirectToWhere] = useState("")
    const [redirectState, setRedirectState] = useState({})
    const [redirectTimeCount, setRedirectTimeCount] = useState(5)

    const setAppContextDefaultValues = () => {
        setOpenSideDrawer(true);
        setProfileDropDown(false);
        setOpenNotificationDropDown(false);
        setMainSnackEvent(false);
        setMainSnackMessage("");
        setMainSnackSeverity("error");
        setMainSnackMargin(true);
        setOpenAlert(false);
        setAlertHeader("");
        setAlertType("alert");
        setAlertMessage("");
        setAlertNavigateTo(null);
        setProfileImage("");
        setCurrentAccount("");
        setAccounts(null);
        setRefetchAccounts(true);
        setRefetchWidgets(true);
        setWidgets([]);
        setOpenStocks(false);
        // setOpenDropDown(false);
        setBotOpen(false);
        setSideBarVisible(false)
        setNoStartup(false)
        setFetchDropDownStartupData(true)
        setOpenRedirectTimer(false)
        setRedirectMessage("")
        setRedirectToWhere("")
        setRedirectState({})
        setRedirectTimeCount(5)
    }


    return (
        <>
            <AppContext.Provider value={{
                mainSnackEvent,
                mainSnackMessage,
                mainSnackSeverity,
                mainSnackMargin,
                openAlert,
                alertHeader,
                alertType,
                alertMessage,
                alertNavigateTo,
                setMainSnackMargin,
                setMainSnackEvent,
                setMainSnackMessage,
                setMainSnackSeverity,
                profileImage,
                setProfileImage,
                openProfileDropDown,
                profileRef,
                notificationDropDownRef,
                stocksRef,
                // bankRef,
                botRef,
                setProfileDropDown,
                openNotificationDropDown,
                setOpenNotificationDropDown,
                openSideDrawer,
                setOpenSideDrawer,
                socket,
                setAlertHeader,
                setAlertType,
                setAlertMessage,
                setOpenAlert,
                setAlertNavigateTo,
                currentAccount,
                setCurrentAccount,
                accounts,
                setAccounts,
                refetchAccounts,
                setRefetchAccounts,
                setAppContextDefaultValues,
                refetchWidgets,
                setRefetchWidgets,
                widgets,
                setWidgets,
                openStocks,
                setOpenStocks,
                // openDropDown,
                // setOpenDropDown,
                botOpen,
                setBotOpen,
                sideBarVisible,
                setSideBarVisible,
                openSuccessStartupInfo,
                setOpenSuccessStartupInfo,
                fetchDropDownStartupData,
                setFetchDropDownStartupData,
                noStartup,
                setNoStartup,
                openRedirectTimer, setOpenRedirectTimer,
                redirectMessage, setRedirectMessage,
                redirectToWhere, setRedirectToWhere,
                redirectState, setRedirectState,
                redirectTimeCount, setRedirectTimeCount
            }}>
                {children}
            </AppContext.Provider>

        </>

    )
}
export const StartupContext = createContext();
export const AssetContext = createContext();

export const AssetProvider = ({ children }) => {
    const [assetCardsLoading, setAssetCardsLoading] = useState(true)
    const [currentCardDetails, setCurrentCardDetails] = useState({})
    const [shiftFeature, setShiftFeature] = useState("")
    const [currentSelectedAsset, setCurrentSelectedAsset] = useState("owned")
    const [currentPage, setCurrentPage] = useState(1)
    const [cards, setCards] = useState([])

    return (
        <>
            <AssetContext.Provider value={{ currentPage, setCurrentPage, assetCardsLoading, setAssetCardsLoading, setCards, cards, currentCardDetails, setCurrentCardDetails, shiftFeature, setShiftFeature, currentSelectedAsset, setCurrentSelectedAsset }}>
                {children}
            </AssetContext.Provider>

        </>

    )
}