import React from 'react';
import { useNavigate } from 'react-router';

const NoData = ({ message, className = "", textColor = "#afafaf", link = false, navigateTo, linkDisplayMessage = "" }) => {
    const navigate = useNavigate()
    return (
        <div className={`no-content ${className}`}>
            <span className='no-data-message' style={{ color: textColor }}>{message}</span>
            {link &&
                <span onClick={() => navigate(navigateTo)} className="no-data-redirect-link">{linkDisplayMessage}</span>
            }
        </div>
    );
}

export default NoData;
