import React, { useEffect, useState } from "react";
import TokenIcon from "@mui/icons-material/Token";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";

const RedirectDialogues = ({ message, setOpenRedirectModal, navigateTo, timeCount = 5, redirectState }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(timeCount);
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    if (count === 0) {
      clearInterval(countdownInterval);
      // if (isWallet) {
      //     navigate('/bank/login', { state: { bankName: bankName, redirect: '/bank/wallet/add-money' } })
      // } else if (isTransaction) {
      //     navigate(`/bank/login`, { state: { bankName: bankName, redirect: "/bank/transaction" } });
      // } else if (isStocks) {
      //     navigate('/chat', { state: { chatScreen: 'stockTradeChat' } })
      // } else if (isCommodities) {
      //     navigate('/chat', { state: { chatScreen: 'commoditiesTradeChat' } })
      // }
      // else {
      setOpenRedirectModal(false);
      navigate(navigateTo, redirectState);
      // }
    }
    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);
  return (
    <div className="redirect-to-count-down-wrapper">
      <TokenIcon style={{ color: "#ed6c02", marginRight: "0.5rem" }} />
      {message}
      {` in ${count} seconds`}
      <Close
        style={{ cursor: "pointer", fontSize: "1.2rem", marginLeft: "0.5rem" }}
        onClick={() => {
          setOpenRedirectModal(false);
        }}
      />
    </div>
  );
};

export default RedirectDialogues;
