import React from 'react';
import Base from '../comp/Base';
import { useNavigate } from 'react-router';

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <Base>
            <div className="page-not-found-wrapper">
                <h1 className="not-found-main-header">404</h1>
                <header className="not-found-sub-header">Page Not Found</header>
                <div className="description">The page you are looking for doesn't seem to <br />exist or temporarily unavailable.</div>
                <button onClick={() => navigate("/", { replace: true })} className="pluto-main-button">Go To Home</button>
            </div>
        </Base>
    );
}

export default PageNotFound;
