import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getFooterBoatNews } from "../MiddleWares/AssetNews/AssetNewsApi";
import PlutocratBoatScroll from "./PlutocratBoatScroll";
import StockCommodities from "./StockCommodities";
import { getStockData } from "../MiddleWares/FooterNews/FooterNewsApi";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import fmt from "indian-number-format";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AppContext, socket } from "../services/AppContext";
import { Tooltip } from "@mui/material";
import { useContext } from "react";

function PlutocratInfoScroll({ role }) {
  const { openStocks, setOpenStocks, stocksRef } = useContext(AppContext);
  const [index, setIndex] = useState(0);
  const [news, setNews] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableRefresh, setDisableRefresh] = useState(false);

  const fetchNews = async () => {
    await getFooterBoatNews()
      .then((res) => {
        setNews(res?.data?.assetNews);
      })
      .catch((err) => {
        console.log(err?.message)
      })
  };


  const [numDots, setNumDots] = useState(1);
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots) => {
        if (dots?.length === numDots && numDots < 3) {
          if (numDots < 3) {
            setNumDots((numDots) => numDots + 1);
            dots = dots + ".";
          } else {
            setNumDots(1);
            dots = ".";
          }
          return dots;
        } else {
          setNumDots(1);
          dots = ".";
          return dots;
        }
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, [numDots]);

  socket.off("stock-details").on("stock-details", (data) => {
    if (data.err) {
      setTimeout(() => {
        socket.emit("stock-updates");
      }, 10000);
    } else {
      setStocks(data?.stocks);
      setTimeout(() => {
        socket.emit("stock-updates");
      }, 300000);
    }
  });

  const fetchStocksData = () => {
    setLoading(true);
    getStockData({ stockType: "stocks" })
      .then((res) => {
        // console.log("res", res)
        setStocks(res?.data?.data);
      }).finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    if (role === "user")
      fetchStocksData();
  }, []);


  const handleRefresh = () => {
    if (!disableRefresh) {
      setDisableRefresh(true);
      fetchStocksData();
      setTimeout(() => setDisableRefresh(false), 60000); // re-enable button after one minute
      document.querySelector(".refresh-icon").style.transition = "transform 0.5s ease-in-out";
      document.querySelector(".refresh-icon").style.transform = "rotate(360deg)";
    }
  };

  useEffect(() => {
    // fetchGoldPrice()
    fetchNews();
    // fetchStocksData();
  }, []);
  socket.off("asset-news-created").on("asset-news-created", () => {
    fetchNews();
  })

  useEffect(() => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count < stocks?.length - 1) {
        count++;
        setIndex(count);
      } else {
        count = 0;
        setIndex(0);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [stocks]);

  return (
    <div style={{ display: "flex", flexDirection: "row", width: 'calc(100% - 4.1rem)', float: 'right' }}>
      {role === "user" &&
        <div
          ref={stocksRef}
          id="stock-data"
          style={{ width: "14%", backgroundColor: "black", position: "relative", height: "50px", alignItems: "center", textAlign: "center", display: "flex", justifyContent: "center" }}
        >
          {loading ? (
            <>
              <div className="fds-loading">
                <span style={{ color: "white", fontSize: "0.75rem" }}>Loading{dots}</span>
              </div>
            </>
          ) : (
            <>
              {stocks?.length > 0 ? (
                <>
                  <div style={{ color: "white", alignItems: 'center', fontSize: "12px", width: "80%", cursor: 'pointer' }} onClick={() => { setOpenStocks(!openStocks) }}>
                    <span style={{ display: "block" }}>
                      {stocks[index]?.symbol} : &#8377;{fmt.format(Math.round(stocks[index]?.currentPrice * 100) / 100)}
                    </span>
                    {stocks[index]?.change >= 0 ? (
                      <span style={{ color: "green", display: "flex", justifyContent: "center" }}>
                        +{fmt.format(Math.round(stocks[index]?.change * 100) / 100)} {`(${fmt.format(Math.round(stocks[index]?.percent * 100) / 100)}%)`}
                        <ArrowUpwardIcon style={{ fontSize: "0.9rem" }} />
                      </span>
                    ) : (
                      <span style={{ color: "red", display: "flex", justifyContent: "center" }}>
                        {fmt.format(Math.round(stocks[index]?.change * 100) / 100)} {`(${fmt.format(Math.round(stocks[index]?.percent * 100) / 100)}%)`}
                        <ArrowDownwardIcon style={{ fontSize: "0.9rem" }} />
                      </span>
                    )}
                  </div>
                  <div style={{ width: "15%", alignItems: "left", display: "flex", justifyContent: "flex-start" }}>
                    <Tooltip title="Refresh">
                      <RefreshIcon
                        className="refresh-icon"
                        style={{ color: disableRefresh ? "gray" : "white", fontSize: "1.2rem", cursor: "pointer" }}
                        disabled={disableRefresh}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
                  <span style={{ fontSize: "0.6rem", color: "white", width: "85%" }}>
                    Your stocks & commodities <br />
                    will be displayed here
                  </span>
                  <div style={{ width: "15%", alignItems: "left", display: "flex", justifyContent: "flex-start" }}>
                    <Tooltip title="Refresh">
                      <RefreshIcon
                        className="refresh-icon"
                        style={{ color: disableRefresh ? "gray" : "white", fontSize: "1.2rem", cursor: "pointer" }}
                        disabled={disableRefresh}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </>
          )}
          <StockCommodities stocks={stocks} setOpenStocks={setOpenStocks} openStocks={openStocks} />
        </div>
      }

      <div id="info-scroll" style={{ width: "86%", height: '50px', display: 'flex', justifyContent: 'center' }}>
        <Marquee gradientWidth="0">
          {news?.length > 0 ?
            news?.map((eachNews) => {
              if (eachNews.type === "boats") {
                return <PlutocratBoatScroll eachNews={eachNews} key={eachNews?._id} />;
              } else {
                return null;
              }
            })
            :
            <div style={{ color: 'white', display: 'flex', justifyContent: 'center' }}><span>Welcome to Plutocrat!</span></div>
          }
        </Marquee>
      </div>
    </div>
  );
}

export default PlutocratInfoScroll;
