import React, { useCallback, useEffect, useState } from "react";
// let i = 1;
const EllipsisLoading = ({ text = [] }) => {
  const [newName, setNewName] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);

  const shuffle = useCallback(() => {
    if (currentIndex === -1) {
      // show the first three texts randomly
      const index = Math.floor(Math.random() * text?.length);
      setNewName(text[index]);
    } else if (currentIndex < text?.length) {
      // show the remaining texts sequentially
      setNewName(text[currentIndex]);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentIndex, text]);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
      }}
    >
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text?.length > 0 && newName && (
        <span id="load_text" style={{ fontSize: "0.8rem", color: "#afafaf", transition: "all 0.3s ease", marginTop: "1rem", textAlign: "center" }}>
          {newName}
        </span>
      )}
    </div>
  );
};

export default EllipsisLoading;
