import React from "react";
import GenderBasedAvatar from "../../data/GenderBasedAvatar";
import GenderAvatar from "../GenderAvatar";

const ImageViewer = ({ className = "", user = null, size = 40, border = true, returnOnlyImage = false }) => {
  let image = "";
  let initials = "";

  if (user?.profile) {
    try {
      const blob = new Blob([Int8Array.from(user?.profile?.data?.data)], { type: user?.profile?.contentType });
      image = window.URL.createObjectURL(blob);
    } catch (err) {
      image = "";
    }
  }
   else {
    // If no user profile, extract initials from user name or other data
    initials = user?.email?.substring(0, 2).toUpperCase() || "";
  }
  return (
    <>
      {returnOnlyImage && (image || user?.gender) ?
        <img className={className} loading="lazy" src={image || GenderBasedAvatar[user?.gender?.toLowerCase()]} alt="gender avatar" />
        :
        <GenderAvatar className={className} avatar={image || GenderBasedAvatar[user?.gender?.toLowerCase()]} initials={initials} border={border} shadow={false} avatarWidth={size} />
      }
    </>
  );
};

export default ImageViewer;
