import React from 'react';

const GenderAvatar = ({ className = "", avatar, avatarWidth = 100, shadow = true, border = true, initials }) => {
    let content;

    if (avatar) {
        // Display the image if avatar is provided
        content = <img className={className} loading="lazy" width={`${avatarWidth}`} height={`${avatarWidth}`} src={avatar} alt="gender avatar" />;
    } else if (initials) {
        // Display the initials if no avatar is provided
        const displayInitials = initials.substring(0, 2).toUpperCase();
        content = <div className="initials">{displayInitials}</div>;
    } else {
        // Default content if neither avatar nor initials are provided
        content = <div className="initials">NA</div>;
    }

    return (
        <>
            <div
                style={{
                    width: `${avatarWidth}px`,
                    height: `${avatarWidth}px`,
                    background: "#c98ae0",
                    boxShadow: shadow ? "0px 0px 40px #9d31e0" : "",
                    border: border ? "2px solid #2B0D39" : "",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Center align content
                    borderRadius: '50%', // Make it circular
                }}
                className="avatar-background">
                {content}
            </div>
        </>
    );
}

export default GenderAvatar;
