import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userRedux";
import thunk from "redux-thunk"
// import startupBankReducer from "./startupBankRedux"
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import bankUserSlice from "./bankUserSlice";
// import BankApiCalls from "./BankApiCalls";

const persistConfig = {
  key: "root",
  // version: 1,
  storage,
  // blackList: [BankApiCalls.reducerPath]
};

// const rootReducer = combineReducers({ user: userReducer, bank: bankReducer, startupBank: startupBankReducer, [ApiHelper.reducerPath] : ApiHelper.reducer});
const rootReducer = combineReducers({
  user: userReducer,
  // bank: bankUserSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    thunk,
    // BankApiCalls.middleware
  ]
});
// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

export default store;
