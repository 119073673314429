import { httpGET } from "../../utils/axiosInstance"
import { gameProtectedRoute } from "../../utils/constants"

export const clearAllNotification = async () => {
    let url = `/auth${gameProtectedRoute}/user/clear-notification`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}
