import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PlutocratTimer from "./PlutocratTimer";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import { ArrowDropDown, ArrowDropUp, Clear, HorizontalRule } from "@mui/icons-material";
import TokenIcon from "@mui/icons-material/Token";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { AppContext } from "../../services/AppContext";
import ProfileMenuDropDown from "./Profile/ProfileMenuDropDown";
import { CircularProgress } from "@mui/material";
import NotificationDropDown from "./Notifications/NotificationDropDown";
import { getUserNetworth } from "../../MiddleWares/LeaderBoard/LeaderBoardApi";
import "intro.js/introjs.css";
import { useNavigate } from "react-router";

const PlutocratAppBar = () => {
  const { openSideDrawer, setOpenSideDrawer } = useContext(AppContext);
  const [netWorthLoading, setNetWorthLoading] = useState(false);
  const [netWorthDetails, setNetWorthDetails] = useState({});
  const user = useSelector((state) => state.user.currentUser);
  const { socket } = useContext(AppContext);
  const navigate = useNavigate();
  // const [appBarTourCompleted, setAppBarTourCompleted] = useState(() => {
  //     return localStorage.getItem('appBarTourCompleted') === 'true';
  // });

  socket.off("networthUpdated").on("networthUpdated", () => {
    fetchNetWorth();
  });

  const fetchNetWorth = async () => {
    setNetWorthLoading(true);
    getUserNetworth()
      .then((res) => {
        setNetWorthDetails(res?.data?.netWorthDetails);
        setNetWorthLoading(false);
      })
      .catch(() => {
        setNetWorthLoading(false);
      });
  };

  useEffect(() => {
    fetchNetWorth();
    user && socket.emit("join-new-member", `${user?._id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  socket.off("connect").on("connect", () => {
    if (user) {
      socket.emit("join-new-member", `${user?._id}`);
    }
  });

  // useEffect(() => {
  //     if (!appBarTourCompleted) {
  //         introJs().setOptions({
  //             exitOnOverlayClick: false,
  //             doneLabel: 'End Tour',
  //             steps: [{
  //                 title: 'WELCOME TO PLUTOCRAT!',
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>This is the Home Page`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: ".user-points",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can check your individual points`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: ".user-rank",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can check your rank in the leaderboard`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: ".notification-wrap",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can check your notifications`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: ".profile-dropdown-wrapper",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can find your username,game email and company mail here`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: "#stock-data",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can check your stocks details`
  //             },
  //             {
  //                 title: 'HOME PAGE',
  //                 element: "#info-scroll",
  //                 intro: `<img src=${HomeButton} width='20px' height='20px'></img><br/>Here you can find boat news`
  //             }],
  //         }).oncomplete(function () {
  //             localStorage.setItem('appBarTourCompleted', 'true');
  //             window.location.reload(true);
  //             setAppBarTourCompleted(true);
  //         }).onbeforeexit(function () {
  //             return window.confirm("Are you sure to exit the tour?");
  //         }).onexit(function () {
  //             window.location.reload(true);
  //             localStorage.setItem('appBarTourCompleted', 'true');
  //             setAppBarTourCompleted(true);
  //         }).start()
  //     }
  // }, [appBarTourCompleted]);

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: 1400, height: "3.5rem", boxShadow: "none", backgroundColor: "#333333", margin: "0px" }}>
        <Toolbar disableGutters variant="dense" sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 1.5rem" }}>
          <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
            {openSideDrawer ? (
              <Clear titleAccess="Close Sidebar" onClick={() => setOpenSideDrawer(false)} className={`side-bar-open-icon ${openSideDrawer && "open-rotate-icon"}`} />
            ) : (
              <MenuOutlined titleAccess="Open Sidebar" onClick={() => setOpenSideDrawer(true)} className={`side-bar-open-icon ${openSideDrawer && "open-rotate-icon"}`} />
            )}
            <TokenIcon sx={{ width: "30px", height: "30px", color: "#7e0fc4" }} />
            <div>
              <Typography style={{ padding: 10, fontFamily: "comfortaa", fontSize: "18px", cursor: "pointer" }} onClick={() => navigate("/")}>
                PLUTOCRAT
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "0 1rem" }}>
            <PlutocratTimer width={600} />
            {/* {showRank ? <div style={{
                            backgroundColor: "#0f0015",
                            padding: "8px 15px",
                            display: "flex", alignItems: "center",
                            gap: "0 0.5rem",
                            borderRadius: "var(--primary-radius)",
                            // width:"27vh"
                        }}>
                            <div>
                                <Typography noWrap={true} sx={{ color: "#7e0fc4", fontSize: "12px" }} >
                                    NET WORTH |
                                </Typography>
                            </div>
                            <Typography component={'span'} noWrap={true} sx={{ color: "white", fontSize: "12px" }} data-title="Welcome to Plutocrat!" data-intro="Here, you can see your networth">
                                {netWorthLoading ? (
                                    <span style={{ display: "flex", alignItems: "center" }}>
                                        <CircularProgress size={18} />
                                    </span>
                                ) : (
                                    `₹ ${Math.round(netWorthDetails?.netWorth)}`
                                )}
                            </Typography>
                            <div>
                                <Typography noWrap={true} sx={{ color: netWorthDetails?.rank > netWorthDetails?.previousRank ? "red" : "lightgreen", fontSize: "12px", display: "flex", alignItems: "center" }} data-title="Welcome to Plutocrat!" data-intro="Here, you can see your rank in the leaderboard">
                                    <span className="networth-result">#{netWorthDetails?.rank}</span>
                                    {netWorthDetails?.rank > netWorthDetails?.previousRank && <ArrowDropDown sx={{ fontSize: "1rem", paddingLeft: 0, marginLeft: "0.2rem" }} />}
                                    {netWorthDetails?.rank < netWorthDetails?.previousRank && <ArrowDropUp sx={{ fontSize: "10px", paddingLeft: 0, marginLeft: "0.2rem" }} />}
                                    {netWorthDetails?.rank === netWorthDetails?.previousRank && <HorizontalRule sx={{ fontSize: "10px", paddingLeft: 0, marginLeft: "0.2rem" }} />}
                                </Typography>
                            </div>
                        </div> :  */}
            <div
              style={{
                backgroundColor: "#0f0015",
                padding: "8px 15px",
                display: "flex",
                alignItems: "center",
                gap: "0 0.5rem",
                borderRadius: "var(--primary-radius)",
              }}
            >
              <div className="user-points">
                <Typography noWrap={true} sx={{ color: "#7e0fc4", fontSize: "12px" }}>
                  POINTS |
                </Typography>
              </div>
              <Typography component={"span"} noWrap={true} sx={{ color: "white", fontSize: "12px" }}>
                {netWorthLoading ? (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={18} />
                  </span>
                ) : (
                  `${Math.round(netWorthDetails?.points)}`
                )}
              </Typography>
              <div>
                <Typography noWrap={true} sx={{ color: netWorthDetails?.rank > netWorthDetails?.previousRank ? "red" : "lightgreen", fontSize: "12px", display: "flex", alignItems: "center" }} className="user-rank">
                  <span className="networth-result">#{netWorthDetails?.rank}</span>
                  {netWorthDetails?.rank > netWorthDetails?.previousRank && <ArrowDropDown sx={{ fontSize: "1.3rem", paddingLeft: 0 }} />}
                  {netWorthDetails?.rank < netWorthDetails?.previousRank && <ArrowDropUp sx={{ fontSize: "1.3rem", paddingLeft: 0 }} />}
                  {netWorthDetails?.rank === netWorthDetails?.previousRank && <HorizontalRule sx={{ fontSize: "10px", paddingLeft: 0 }} />}
                </Typography>
              </div>
            </div>
            {/* } */}

            <NotificationDropDown />
            <ProfileMenuDropDown />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default PlutocratAppBar;
