import React from "react";
import useActivateToast from "../../../../customHooks/toast/useActivateToast";
import { clearAllNotification } from "../../../../MiddleWares/Notification/NotificationApi";
import { emailTimeDisplay } from "../../../../utils/getDateTime";
import NoData from "../../../message/NoData";

const NotificationBody = ({ notifications, setNotifications }) => {
  const { activateSneak } = useActivateToast();

  const clearNotification = () => {
    clearAllNotification()
      .then((res) => {
        setNotifications([]);
        activateSneak(res?.data?.message, "success");
      })
      .catch((err) => {
        activateSneak(err?.data?.message, "error");
      });
  };
  return (
    <>
      <div className="notification-bar">
        <div className="upp-arrow"></div>
        <div className="notification-header">
          <div>
            <span className="notification-main-header">Notifications</span>
            <div className="line-divider"></div>
          </div>
          {notifications?.length > 0 && (
            <div>
              <span className="cancel-button" onClick={() => clearNotification()}>
                Clear All
              </span>
            </div>
          )}
        </div>
        <div className="all-notifications">
          {notifications?.length > 0 ? (
            <>
              {notifications.map((eachNotification) => (
                <div key={eachNotification?._id} className="each-notification">
                  <div className="title-time">
                    <span className="title">{eachNotification?.title}</span>
                    <span className="time">{emailTimeDisplay(eachNotification?.time)}</span>
                  </div>
                  <span className="message">{eachNotification?.message}</span>
                </div>
              ))}
            </>
          ) : (
            <NoData message={"No new notifications to show at \nthe moment."} />
            // <div className="no-notifications">You have 0 notifications</div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationBody;
