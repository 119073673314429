import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PlutocratTimer from "./PlutocratTimer";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import { Clear } from "@mui/icons-material";
import TokenIcon from "@mui/icons-material/Token";
import { useContext } from "react";
import { AppContext } from "../../services/AppContext";
import "intro.js/introjs.css";
import { useNavigate } from "react-router";
import ProfileMenuInstructorDropDown from "./Profile/ProfileMenuInstructorDropDown";

const PlutocratInstructorAppBar = () => {
    const { openSideDrawer, setOpenSideDrawer } = useContext(AppContext);
    const navigate = useNavigate();

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: 1400, height: "3.5rem", boxShadow: "none", backgroundColor: "#333333", margin: "0px" }}>
                <Toolbar disableGutters variant="dense" sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 1.5rem" }}>
                    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                        {openSideDrawer ? (
                            <Clear titleAccess="Close Sidebar" onClick={() => setOpenSideDrawer(false)} className={`side-bar-open-icon ${openSideDrawer && "open-rotate-icon"}`} />
                        ) : (
                            <MenuOutlined titleAccess="Open Sidebar" onClick={() => setOpenSideDrawer(true)} className={`side-bar-open-icon ${openSideDrawer && "open-rotate-icon"}`} />
                        )}
                        <TokenIcon sx={{ width: "30px", height: "30px", color: "#7e0fc4" }} />
                        <div>
                            <Typography style={{ padding: 10, fontFamily: "comfortaa", fontSize: "18px", cursor: "pointer" }} onClick={() => navigate("/")}>
                                PLUTOCRAT
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "0 1rem" }}>
                        <PlutocratTimer width={600} />
                        {/* <NotificationDropDown /> */}
                        <ProfileMenuInstructorDropDown/>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
};
export default PlutocratInstructorAppBar;
