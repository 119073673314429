import { httpGET } from "../../utils/axiosInstance";

export const getUserDetails = async (usersType) => {
    let url = `/auth/game/protected/users-details/${usersType}`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}


export const getProfileDropDownData = async () => {
    let url = `/auth/game/protected/profile/dropdown`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}
