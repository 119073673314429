// URL for the backend
export const API = process.env.REACT_APP_BACKEND_API
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
// URL for the backend
// export const API = 'http://192.168.1.6:8080/api'
// export const SOCKET_URL = 'http://192.168.1.6:8080'
// export const API = 'https://plutobackend.in/api'
// export const SOCKET_URL = 'https://plutobackend.in'

//testing
export const baseAPI = process.env.NODE_ENV === "production" ? "api" : API