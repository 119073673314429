import { Cancel, CheckCircle } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React from "react";

const confirmationBoxStyles = {
  fontFamily: "comfortaa",
  "& .MuiDialog-paper": {
    marginLeft: "100px",
    borderRadius: "var(--least-radius)",
    background: "black",
    border: "1px solid rgb(126, 15, 196)"
  }
}
const Confirmation = ({ setOpenConfirmModal, callback, onClose, openConfirmModal, message, note }) => {
  const confirmCallbackHandler = () => {
    callback();
    setOpenConfirmModal(false);
  };
  const closeHandler = () => {
    setOpenConfirmModal(false);
  };
  return (
    <>
      <Dialog onClose={closeHandler} sx={confirmationBoxStyles} open={openConfirmModal}>
        <div className="confirm-wrapper">
          <div className="confirm-header">Confirm the action</div>
          <div className="confirm-message">{message && message}</div>
          <div className="confirm-note">{note && note}</div>
          <div className="yes-no">
            <button onClick={confirmCallbackHandler} className="pluto-main-button">
              <CheckCircle />
              Yes
            </button>
            <button onClick={() => {setOpenConfirmModal(false);}} className="pluto-main-button dlt-button">
              <Cancel />
              No
            </button>

          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Confirmation;
