import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import AuthRoutes from "./services/AuthRoutes";
import { ADD_STUDENTS, ASSETS, ASSETS_ROUTES, BANK_ROUTES, BOATS_ROUTES, CHAT, CHAT_GPT, COMMUNICATION, COMMUNICATION_EMAIL_ID, EACH_STUDENT, GAME_LOGIN, GAME_RESET_PASSWORD, HOME, HOME_DASHBOARD, INTRO, LEADER_BOARD, MANUFACTURER, MARKET, MARKETING, MARKETING_HISTORY, MARKET_ROUTES, NEWS, NEW_MARKETING, NOT_FOUND, ORDERS, PLUTOLINK, PROFILE, PROFILE_PAGE, STARTUP_ROUTES, STUDENT_DETAILS, USERS_LIST, VIEW_CLASSES } from "./services/routes";
import { AppContext } from "./services/AppContext";
import PageNotFound from "./pages/PageNotFound";
import LoginCheckRoute from "./services/LoginCheckRoute";
import PlutocratBackdrop from "./comp/PlutocratBackdrop";
import PageLoading from "./comp/Loading/PageLoading";
import AlertDialogue from "./comp/Dialogues/AlertDialogue";
import StartupScreenRoutes from "./screens/StartupScreens";
import SnackEvent from "./comp/SnackEvent";
import { ToastContainer } from "react-toastify";
import UsersList from "./screens/UsersListScreens";
import { useContext } from "react";
import Cookies from "js-cookie";
import "./styles/main.scss";
import 'react-toastify/dist/ReactToastify.css';
import RedirectDialogues from "./comp/Dialogues/RedirectDialogues";
import { Modal } from "@mui/material";
import PlutocratInstructorBackdrop from "./comp/PlutocratInstructorBackdrop";
import jwt_decode from "jwt-decode";
import FirstLogin from "./comp/Dialogues/FirstLogin";

const Home = lazy(() => import("./screens/HomeScreen"))
const BankScreenRoutes = lazy(() => import("./screens/BankScreens"))
const MailScreen = lazy(() => import("./screens/MailScreens"))
const Market = lazy(() => import("./screens/MarketScreens/Market"))
const MarketRoutes = lazy(() => import("./screens/MarketScreens"))
const Marketing = lazy(() => import("./screens/MarketingScreens"));
//new
const NewMarketing = lazy(() => import("./screens/NewMarketingScreens"))
const LeaderBoardMain = lazy(() => import("./screens/LeaderBoard"))
const News = lazy(() => import("./screens/NewsScreens"))
const GamePasswordReset = lazy(() => import("./screens/AuthScreens/components/GamePasswordReset"))
const Login = lazy(() => import("./screens/AuthScreens"))
const Profile = lazy(() => import("./screens/ProfileScreen"))
const SocialMedia = lazy(() => import("./screens/SocialMediaScreens"))
const BoatScreenRoutes = lazy(() => import("./screens/BoatScreens"))
const OrdersScreen = lazy(() => import("./screens/OrdersScreen/Index"))
const AssetScreen = lazy(() => import("./screens/AssetScreen"))
const Chat = lazy(() => import("./screens/ChatScreens"))
const ChatGPT = lazy(() => import("./screens/ChatGptScreen"));
const LaunchScreen = lazy(() => import("./comp/LaunchScreen"))
const AssetRoutes = lazy(() => import("./screens/AssetScreen/AssetRoutes"))
const MarketManufacturer = lazy(() => import("./screens/MarketScreens/MarketPages/Manufacturer"))
const HomeDashboard = lazy(() => import("./screens/NewHomeScreen"))
const MarketingHistory = lazy(() => import("./screens/NewMarketingScreens/components/common/MarketingHistory"));

//Instructor Screens
const InstructorHome = lazy(() => import("./instructorScreens/HomeScreens"));
const InstructorAddStudents = lazy(() => import("./instructorScreens/AddStudentsScreens"));
const InstructorViewStudents = lazy(() => import("./instructorScreens/ViewStudentsScreens"));
const InstructorEachStudent = lazy(() => import("./instructorScreens/ViewStudentsScreens/components/EachStudentDetails"));
const InstructorStudentsDetails = lazy(() => import("./instructorScreens/StudentDetails"));
const InstructorProfile = lazy(() => import("./instructorScreens/ProfileScreens"))



function App() {
  const { openAlert, alertHeader, alertType, alertMessage, alertNavigateTo, mainSnackEvent, mainSnackMessage, mainSnackSeverity,
    mainSnackMargin, setProfileDropDown, profileRef, notificationDropDownRef, setOpenNotificationDropDown, setOpenAlert, setAlertType,
    setAlertHeader, setAlertMessage, setAlertNavigateTo, setMainSnackEvent, stocksRef, setOpenStocks, setBotOpen, botRef, sideBarVisible,
    setSideBarVisible, openRedirectTimer, setOpenRedirectTimer, redirectToWhere, redirectMessage, redirectTimeCount, redirectState } = useContext(AppContext)


  // getting user login information
  const user = useSelector((state) => state.user.currentUser);
  let userRole = ""
  let grantAccess = false
  if (user) {
    const accessToken = Cookies.get('access-token'); // Retrieve the access token
    const refreshToken = Cookies.get('refresh-token'); // Retrieve the access token
    if (accessToken && refreshToken) {
      grantAccess = true
      const decodedToken = jwt_decode(accessToken);
      userRole = decodedToken.role;
    }
  }

  // let hasAccess = false



  // useEffect(() => {
  //   introJs().setOption("dontShowAgain", true).start()
  //   introJs().setOptions({
  //     exitOnOverlayClick: false
  //   }).start();
  // }, []);

  const handleClickOutside = (event) => {
    if (!profileRef?.current?.contains(event.target)) {
      setProfileDropDown(false)
    }
    if (!notificationDropDownRef?.current?.contains(event.target)) {
      setOpenNotificationDropDown(false)
    }
    if (!stocksRef?.current?.contains(event?.target)) {
      setOpenStocks(false)
    }
    // if (!bankRef?.current?.contains(event?.target)) {
    //   setOpenDropDown(false)
    // }
    if (!botRef?.current?.contains(event?.target)) {
      setBotOpen(false)
    }
  };

  window.addEventListener('offline', function (e) {
    setOpenAlert(true)
    setAlertType("alert")
    setAlertHeader("You are not connected!")
    setAlertMessage("Check your internet connection once! 🥲🥲")
    setAlertNavigateTo(null)
  });

  window.addEventListener('online', function (e) {
    setOpenAlert(true)
    setAlertType("love")
    setAlertHeader("You are connected!")
    setAlertMessage("Yaay! You are back. 😍😍")
    setAlertNavigateTo(null)
  });

  useEffect(() => {
    const refreshToken = Cookies.get('refresh-token');
    if (refreshToken && user) {
      setSideBarVisible(true)
      // hasAccess = true
    }
  }, [setSideBarVisible, user])

  return (
    <>
      <>
        <ToastContainer />
        <div style={{ background: "#333333" }} onClick={handleClickOutside} >

          {/* based on the user role different screens were renders i.e for instructor only instructors screens will display here and vice versa */}
          {/* {user && userRole === "instructor" ? <> */}
          {/* {user && sideBarVisible && <PlutocratBackdrop />} */}
          {(user && sideBarVisible) && (userRole === "instructor" ? <PlutocratInstructorBackdrop /> : <PlutocratBackdrop />)}
          <Suspense fallback={<PageLoading loginScreen={(!user || !sideBarVisible) ? true : false} />}>
            <Routes>
              {(user && grantAccess) ?
                (userRole === "instructor" ? <>
                  <Route exact path={HOME} element={<AuthRoutes component={InstructorHome} />} />
                  <Route exact path={ADD_STUDENTS} element={<AuthRoutes component={InstructorAddStudents} />} />
                  <Route exact path={VIEW_CLASSES} element={<AuthRoutes component={InstructorViewStudents} />} />
                  <Route exact path={EACH_STUDENT} element={<AuthRoutes component={InstructorEachStudent} />} />
                  <Route exact path={STUDENT_DETAILS} element={<AuthRoutes component={InstructorStudentsDetails} />} />
                  <Route exact path={PROFILE_PAGE} element={<AuthRoutes component={InstructorProfile} />} />
                  <Route path={NOT_FOUND} element={<AuthRoutes component={PageNotFound} />} />
                </>
                  :
                  <>
                    <Route exact path={HOME} element={<AuthRoutes component={Home} />} />
                    <Route exact path={HOME_DASHBOARD} element={<AuthRoutes component={HomeDashboard} />} />
                    <Route exact path={PROFILE} element={<AuthRoutes component={Profile} />} />
                    <Route exact path={BANK_ROUTES} element={<AuthRoutes component={BankScreenRoutes} />} />
                    <Route exact path={COMMUNICATION} element={<AuthRoutes component={MailScreen} />} />
                    <Route exact path={COMMUNICATION_EMAIL_ID} element={<AuthRoutes component={MailScreen} />} />
                    <Route exact path={STARTUP_ROUTES} element={<AuthRoutes component={StartupScreenRoutes} />} />
                    <Route path={MARKET} element={<AuthRoutes component={Market} />} />
                    <Route path={MARKET_ROUTES} element={<AuthRoutes component={MarketRoutes} />} />
                    <Route path={LEADER_BOARD} element={<AuthRoutes component={LeaderBoardMain} />} />
                    <Route path={NEWS} element={<AuthRoutes component={News} />} />
                    <Route path={ASSETS} element={<AuthRoutes component={AssetScreen} />} />
                    <Route path={ASSETS_ROUTES} element={<AuthRoutes component={AssetRoutes} />} />
                    <Route exact path={PLUTOLINK} element={<AuthRoutes component={SocialMedia} />} />
                    <Route exact path={INTRO} element={<AuthRoutes component={LaunchScreen} />} />
                    <Route path={MARKETING} element={<AuthRoutes component={Marketing} />} />
                    <Route path={NEW_MARKETING} element={<AuthRoutes component={NewMarketing} />} />
                    <Route path={MARKETING_HISTORY} element={<AuthRoutes component={MarketingHistory} />} />
                    <Route path={BOATS_ROUTES} element={<AuthRoutes component={BoatScreenRoutes} />} />
                    <Route path={USERS_LIST} element={<AuthRoutes component={UsersList} />} />
                    <Route path={ORDERS} element={<AuthRoutes component={OrdersScreen} />} />
                    <Route path={CHAT} element={<AuthRoutes component={Chat} />} />
                    <Route path={CHAT_GPT} element={<AuthRoutes component={ChatGPT} />} />
                    <Route exact path={MANUFACTURER} element={<AuthRoutes component={MarketManufacturer} />} />
                    <Route path={GAME_RESET_PASSWORD} element={<LoginCheckRoute component={GamePasswordReset} />} />
                    <Route path={NOT_FOUND} element={<AuthRoutes component={PageNotFound} />} />
                  </>) :
                <>
                  {/* <Navigate to="/login" /> */}
                  <Route path={NOT_FOUND} element={<AuthRoutes component={PageNotFound} />} />
                  <Route exact path={GAME_LOGIN} element={<LoginCheckRoute component={Login} />} />
                </>
              }
            </Routes>
          </Suspense>
          {/* </> :
            <>
              {user && sideBarVisible && <PlutocratBackdrop />}
              <Suspense fallback={<PageLoading loginScreen={(!user || !sideBarVisible) ? true : false} />}>
                <Routes>
                  <Route exact path={HOME} element={<AuthRoutes component={Home} />} />
                  <Route exact path={HOME_DASHBOARD} element={<AuthRoutes component={HomeDashboard} />} />
                  <Route exact path={PROFILE} element={<AuthRoutes component={Profile} />} />
                  <Route exact path={BANK_ROUTES} element={<AuthRoutes component={BankScreenRoutes} />} />
                  <Route exact path={COMMUNICATION} element={<AuthRoutes component={MailScreen} />} />
                  <Route exact path={COMMUNICATION_EMAIL_ID} element={<AuthRoutes component={MailScreen} />} />
                  <Route exact path={STARTUP_ROUTES} element={<AuthRoutes component={StartupScreenRoutes} />} />
                  <Route path={MARKET} element={<AuthRoutes component={Market} />} />
                  <Route path={MARKET_ROUTES} element={<AuthRoutes component={MarketRoutes} />} />
                  <Route path={LEADER_BOARD} element={<AuthRoutes component={LeaderBoardMain} />} />
                  <Route path={NEWS} element={<AuthRoutes component={News} />} />
                  <Route path={ASSETS} element={<AuthRoutes component={AssetScreen} />} />
                  <Route path={ASSETS_ROUTES} element={<AuthRoutes component={AssetRoutes} />} />
                  <Route exact path={PLUTOLINK} element={<AuthRoutes component={SocialMedia} />} />
                  <Route exact path={INTRO} element={<AuthRoutes component={LaunchScreen} />} />
                  <Route path={MARKETING} element={<AuthRoutes component={Marketing} />} />
                  <Route path={BOATS_ROUTES} element={<AuthRoutes component={BoatScreenRoutes} />} />
                  <Route path={USERS_LIST} element={<AuthRoutes component={UsersList} />} />
                  <Route path={ORDERS} element={<AuthRoutes component={OrdersScreen} />} />
                  <Route path={CHAT} element={<AuthRoutes component={Chat} />} />
                  <Route path={NOT_FOUND} element={<PageNotFound />} />
                  <Route path={GAME_RESET_PASSWORD} element={<LoginCheckRoute component={GamePasswordReset} />} />
                  <Route exact path={GAME_LOGIN} element={<LoginCheckRoute component={Login} />} />
                  <Route exact path={MANUFACTURER} element={<MarketManufacturer />} />
                </Routes>
              </Suspense>
            </>} */}

        </div>
        <SnackEvent snackMargin={mainSnackMargin} time={8000} message={mainSnackMessage} open={mainSnackEvent} setOpen={setMainSnackEvent} severity={mainSnackSeverity} />
        <AlertDialogue type={alertType} open={openAlert} redirect={alertNavigateTo} setOpen={setOpenAlert} message={alertMessage} header={alertHeader} />
        {/* To display countdown before redirecting to somewhere */}
        <Modal
          open={openRedirectTimer}
        >
          <div className="redirect-global-wrapper">
            <RedirectDialogues timeCount={redirectTimeCount} navigateTo={redirectToWhere} message={redirectMessage} setOpenRedirectModal={setOpenRedirectTimer} redirectState={redirectState} />
          </div>
        </Modal>

        <Modal open={user && user?.firstLogin}>
          <FirstLogin user={user} />
        </Modal>
      </>

    </>

  );
}

export default App;


// openRedirectTimer, setOpenRedirectTimer,
//   redirectMessage, setRedirectMessage,
//   redirectToWhere, setRedirectToWhere