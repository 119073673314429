import axios from "axios";
import Cookies from "js-cookie";
import { API } from "../../../backend";
import { bankProtectedRoute, STATUS_CODE } from "../../constants";
import { removeBankData } from "../../removeGameData";

const onRequest = (config) => {
    // localStorage.setItem('activeAccount', 'personal');
    let currentActiveAccount = localStorage.getItem('activeAccount')
    let accessToken = Cookies.get('bank-access-token');
    if (currentActiveAccount === "startup") {
        accessToken = Cookies.get('bank-startup-access-token');
    }
    let string = bankProtectedRoute
    if (config?.url?.includes(string)) {
        if (config.headers) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
    }
    return config;
};

const onRequestError = (error) => {
    return Promise.reject(error);
};

const onResponse = (response) => {
    return response;
};

let requestsToRefresh = [];
let isRefreshRequesting = false;

const onResponseError = async (error) => {
    let currentActiveAccount = localStorage.getItem('activeAccount')
    if (error?.response?.status === 403) {
        if (!isRefreshRequesting) {
            isRefreshRequesting = true
            let refreshToken = Cookies.get('bank-refresh-token');
            if (currentActiveAccount === "startup") {
                refreshToken = Cookies.get('bank-startup-refresh-token');
            }
            axios.post(`${API}/bankrefreshToken`,
                { refreshToken }
            ).then(async (res) => {
                if (res?.status === STATUS_CODE.success) {
                    const { accessToken, refreshToken } = res.data;
                    if(currentActiveAccount === "personal"){
                        Cookies.set("bank-access-token", accessToken, { secure: true })
                        Cookies.set("bank-refresh-token", refreshToken, { secure: true })

                    }else{
                        Cookies.set("bank-startup-access-token", accessToken, { secure: true })
                        Cookies.set("bank-startup-refresh-token", refreshToken, { secure: true })
                    }
                    requestsToRefresh.forEach((cb) => cb(accessToken));
                } else {
                    requestsToRefresh.forEach((cb) => cb(null));
                    removeBankData({logout: currentActiveAccount})
                }
            }).catch((error) => {
                removeBankData({logout: currentActiveAccount})
                requestsToRefresh.forEach((cb) => cb(null));
            }).finally(() => {
                requestsToRefresh = [];
                isRefreshRequesting = false;
            });
        }
        return new Promise((resolve, reject) => {
            requestsToRefresh.push((token) => {
                if (token) {
                    error.config._retry = true;
                    error.config.headers.Authorization = `Bearer ${token}`;
                    resolve(axios(error?.config));
                }
                reject(error);
            });
        })
    }
    else if (error?.response?.data?.message === "User not authenticated") {
        requestsToRefresh = [];
        isRefreshRequesting = false;
        try {
            removeBankData({logout: currentActiveAccount})
            return;
        } catch (_error) {
            removeBankData({logout: currentActiveAccount})
            return;
        }
    }
    return Promise.reject(error);
};

export const setupBankInterceptorsTo = (axiosInstance) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

