import React from "react";
import fmt from "indian-number-format";

function PlutocratBoatScroll({ eachNews }) {
    return (
        <>
            <div>
                <span style={{
                    color: "white",
                    padding: "10px",
                    fontsize: "24px",
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    fontSize: "12px",
                }}>
                    <span style={{
                        backgroundColor: "#0f0015",
                        padding: "3px 2px",
                        color: "white",
                        borderRadius: "5px",
                        marginRight: "5px",
                    }}
                    >{eachNews.buyer}
                    </span>
                    bought {eachNews?.quantity}&nbsp;
                    <span
                        style={{
                            backgroundColor: "#8125bb",
                            padding: "3px 1px",
                            color: "white",
                            borderRadius: "5px",
                            marginRight: "5px",
                        }}
                    >{eachNews?.boatType}</span>
                    boat from
                    <span
                        style={{
                            backgroundColor: "#59207d",
                            padding: "3px 1px",
                            color: "white",
                            borderRadius: "5px",
                            marginLeft: "5px",
                            marginRight: "5px"
                        }}
                    >{eachNews.seller}
                    </span>
                    for
                    <span
                        style={{
                            // backgroundColor: "#FF4500",
                            backgroundColor: "#5f0b94",
                            padding: "3px 1px",
                            color: "white",
                            borderRadius: "5px",
                            marginLeft: "5px",
                            marginRight: "5px"
                        }}
                    >&#8377; {fmt.format(Math.round(eachNews.amount))}
                    </span>
                    and earned
                    <span
                        style={{
                            // backgroundColor: "#FF4500",
                            backgroundColor: "#5f0b94",
                            padding: "3px 1px",
                            color: "white",
                            borderRadius: "5px",
                            marginLeft: "5px",
                        }}
                    >{eachNews.points}
                    </span>
                    &nbsp;points
                </span>
            </div>
        </>
    );
}

export default PlutocratBoatScroll;
