import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Clock from 'react-live-clock';

function PlutocratTimer() {
  const [time, setTime] = useState(null);
  useEffect(() => {
    Timer();
  }, []);

  function Timer() {
    var date = new Date();
    // var hours = date.getHours();
    // var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? "pm" : "am";
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? "0" + minutes : minutes;
    // var strTime = hours + ":" + minutes + "" + ampm;
    let month = (date.toLocaleString('default', { month: 'short' }))
    let dateFormat = date.getDate()
    let disPlayDate = `${dateFormat}th ${month} `
    setTime(disPlayDate);
    setInterval(() => {
      Timer();
    }, 600000);
  }

  return (
    <div
      style={{
        borderRadius: "15px",
        // margin: "0 20px 0 20px",
        display: "flex",
        alignItems: "center",
        fontFamily: "comfortaa"
      }}
    >
      <AccessTimeIcon style={{fontSize: "1.2rem"}} />
      <p style={{ paddingLeft: "6px", fontSize: "12px", letterSpacing: "0.06rem" }}><Clock format={'HH:mm:ss'} ticking={true} timezone={''} />, {time}</p>
    </div>
  );
}

export default PlutocratTimer;
