import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import PlutocratDrawerItem from "../snippets/PlutocratDrawerItem";
import PlutocratFooter from "./PlutocratFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../services/AppContext.js";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import notificationAudio from "../assets/audio/mail.mp3";
import { Modal } from "@mui/material";
import StartupSuccessInfo from "./StartupSuccessInfo";
import PlutocratInstructorAppBar from "./AppBar/PlutocratInstructorAppBar";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxShodow: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  boxShodow: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  boxShodow: "none",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PlutocratInstructorBackdrop() {
  const navigate = useNavigate();
  const [home, setHome] = useState(false);
  const [viewStudents, setViewStudents] = useState(false);
  const [addStudents, setAddStudents] = useState(false);
  const [studentsDetails, setStudentsDetails] = useState(false);
  const defaultColor = "#333333";
  const selectedColor = "#0f0015";
  const { openSideDrawer, setOpenSideDrawer, openSuccessStartupInfo, setOpenSuccessStartupInfo } = useContext(AppContext);

  const location = useLocation();
  const audioRef = useRef(null);

  const currentTab = () => {
    select();
    let path = location.pathname;
    if (path === "/") {
      setHome(true);
    } else if (path.includes("/view-students")) {
      setViewStudents(true);
    } else if (path.includes("/add-students")) {
      setAddStudents(true);
    } else if (path.includes("/students-details")) {
      setStudentsDetails(true);
    }
  };

  useEffect(() => {
    currentTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const autoCloseDrawer = () => {
    setTimeout(() => {
      setOpenSideDrawer(false);
    }, 3000);
  };

  const select = () => {
    setHome(false);
    setViewStudents(false);
    setAddStudents(false);
    setStudentsDetails(false);
  };

  React.useEffect(() => {
    autoCloseDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: "flex", position: "relative", zIndex: "1000", background: defaultColor }}>
        <PlutocratInstructorAppBar />
        <Drawer
          variant="permanent"
          open={openSideDrawer}
          PaperProps={{
            sx: {
              backgroundColor: defaultColor,
              border: "none",
            },
          }}
          sx={{ zIndex: 100, position: "relative" }}
        >
          <Toolbar />
          <List style={{ paddingLeft: 10, transition: "all 0.3s ease", paddingTop: '5px' }}>
            <div
              onClick={(e) => {
                select();
                setHome(true);
                navigate("/");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem id="home" defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"Home"} selected={home} />
            </div>

            <div
              id="bank"
              onClick={(e) => {
                select();
                setViewStudents(true);
                navigate("/instructor/view-students");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"home"} icon={"View Students"} selected={viewStudents} />
            </div>
            <div
              style={{ position: "relative" }}
              onClick={(e) => {
                select();
                setAddStudents(true);
                navigate("/instructor/add-students");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"mails"} icon={"Add Students"} selected={addStudents} />
            </div>
            <div
              style={{ position: "relative" }}
              onClick={(e) => {
                select();
                setStudentsDetails(true);
                navigate("/instructor/students-details");
                e.stopPropagation();
              }}
            >
              <PlutocratDrawerItem defaultColor={defaultColor} selectedColor={selectedColor} open={openSideDrawer} path={"mails"} icon={"Students details"} selected={studentsDetails} />
            </div>
          </List>
        </Drawer>
        <PlutocratFooter role={"instructor"} />
        <audio ref={audioRef} src={notificationAudio}></audio>
      </div>
      <Modal open={openSuccessStartupInfo} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div style={{ width: "65%", height: "70%" }} className="modal-wrapper-market startup-success-display-wrapper">
          <StartupSuccessInfo setOpenSuccessStartupInfo={setOpenSuccessStartupInfo} />
        </div>
      </Modal>
    </>
  );
}
