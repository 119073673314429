import { httpDELETE, httpGET, httpPUT } from "../../utils/axiosInstance"
import { gameProtectedRoute } from "../../utils/constants"
/**
 * api to get user profile image data
 * @param {*} accessToken 
 * @param {*} refreshToken 
 * @param {*} dispatch 
 * @returns 
 */
export const getUserProfile = async () => {
    let url = `/auth${gameProtectedRoute}/profile/image`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

export const getUserHaveStartup = async () => {
    let url = `/auth${gameProtectedRoute}/user/isStartup`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

/**
 * To update profile image
 * @param {*} accessToken 
 * @param {*} refreshToken 
 * @param {*} dispatch 
 * @param {*} formData 
 * @returns 
 */
export const updateProfileImage = async (formData) => {
    let url = `/auth${gameProtectedRoute}/profile/upload`
    return await httpPUT(url, formData)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}

/**
 * to delete the profile image
 * @param {*} accessToken 
 * @param {*} refreshToken 
 * @param {*} dispatch 
 * @returns 
 */
export const deleteProfileImage = async () => {
    let url = `/auth${gameProtectedRoute}/profile/delete`
    return await httpDELETE(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err?.response)
        })
}

/**
 * Api to fetch complete details of user
 * @param {*} accessToken 
 * @param {*} refreshToken 
 * @param {*} dispatch 
 * @returns user details or error messages 
 */


export const fetchProfileDetails = async (type) => {
    let url = `/auth${gameProtectedRoute}/profile/${type}/details`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}


export const competitorsFinance = async (financeInput) => {
    let url = `/auth${gameProtectedRoute}/profile/competitor/finance?financeInput=${financeInput}`
    return await httpGET(url)
        .then((res) => {
            return Promise.resolve(res)
        }).catch(async (err) => {
            return Promise.reject(err.response)
        })
}