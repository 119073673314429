import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
// import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import store from './redux/store';
import 'react-tooltip/dist/react-tooltip.css'
import { AuthProvider } from './services/AppContext';
import mainLogo from "./assets/icons/logo.png"

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


const NotMobileApp = () => {
  return (
    <div className="not-mobile-wrapper">
      <div className="company-logo-name">
        <img src={mainLogo} alt="" />
        <span className="name">PLUTOCRAT</span>
      </div>
      <span className="not-mobile-description">
        This application is not available on mobile devices.
      </span>


    </div>
  )
}



// Conditionally render the application
// if (!isMobile) {
//   ReactDOM.render(<App />, document.getElementById('root'));
// } else {
//   // Optionally display a message or redirect to a different page
//   // when the application is not allowed to open on mobile devices.
//   document.getElementById('root').innerHTML = 'This application is not available on mobile devices.';
// }
// const root = ReactDOM.createRoot(document.getElementById('root'));
// const persistedStore = persistStore(store)

if (!isMobile) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const persistedStore = persistStore(store)
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>

  );
} else {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<NotMobileApp />)
  // document.getElementById('root').innerHTML = 'This application is not available on mobile devices.';
}
// root.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistedStore}>
//       <BrowserRouter>
//         <AuthProvider>
//           <App />
//         </AuthProvider>
//       </BrowserRouter>
//     </PersistGate>
//   </Provider>

// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();