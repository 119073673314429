import { useContext } from "react";
import { AppContext } from "../../services/AppContext";

const useActivateToast = () => {
    const { setMainSnackEvent, setMainSnackMessage, setMainSnackSeverity, setMainSnackMargin } = useContext(AppContext);
    const activateSneak = (message, severity, margin= true) => {
        setMainSnackMessage(message)
        setMainSnackSeverity(severity)
        setMainSnackEvent(true)
        if(!margin){
            setMainSnackMargin(false)
        }
    }
   
    return {activateSneak}
}

export default useActivateToast;
