import moment from "moment/moment";

export const getDateTime = (istDate) => {
    var date = new Date(istDate);
    let month = (date.toLocaleString('default', { month: 'short' }))
    let dateFormat = date.getDate()
    let disPlayDate = `${dateFormat} ${month}`
    return disPlayDate
}

export const emailTimeDisplay = (time) => {
    time = new moment(time);
    var currentDate = new moment();
    if (currentDate.isSame(time, "day")) return `Today, ${time.format("h:mm a")}`;
    else if (currentDate.isSame(time, "year")) {
        let timeFormat = time.format("ddd MMM DD");
        let sp = timeFormat.split(" ") // splitting the date into ddd, mmm, DD
        return `${sp[0]}, ${sp[1]} ${sp[2]}`
    }
    else {
        return time.format("MM/DD/YYYY")
    }
}

export const countWeekends = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    let weekendCount = 0;

    while (currentDate <= endDateObj) {
        const dayOfWeek = currentDate.getDay();

        if (dayOfWeek === 0 || dayOfWeek === 6) {
            weekendCount++;
        }

        currentDate.setTime(currentDate.getTime() + oneDay);
    }

    return weekendCount;
}

export const getDiffDays = (startDate, endDate) => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const diffTime = Math.abs(endDate - startDate);
    const diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDay;
}