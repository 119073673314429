import React from "react";
import { useContext } from "react";
import { AppContext } from "../services/AppContext";

const Base = ({ children, loading = false }) => {
    const { openSideDrawer } = useContext(AppContext);
    return (
        <div style={{
            width: openSideDrawer ? "calc(100% - 200px)" : "calc(100% - 65px)",
            marginLeft: openSideDrawer && "200px",
            pointerEvents: loading && "none", filter: loading && "blur(4px)", WebkitFilter: loading && "blur(10px)"
        }} className="body-wrapper">
            {children}
        </div>
    )
}

export default Base;