import React from "react";
import fmt from "indian-number-format";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate } from "react-router";
// import { HorizontalRule } from "@mui/icons-material";

function StockCommodities({ stocks, openStocks }) {
  const navigate = useNavigate();

  const navigateToAssetStocksPage = () => {
    const targetRoute = '/assets#owned#stocks-commodities';
    const currentRoute = window.location.pathname + window.location.hash;

    if (currentRoute !== targetRoute) {
      navigate(targetRoute);
    }
  };

  return (
    <div className="send-message stocks-data">
      {openStocks && (
        <div className="suggestion-box">
          {stocks.map((input, index) => (
            <div className="each-suggestions" key={index} onClick={navigateToAssetStocksPage}>
              <span className="stock-symbol-price">
                {stocks[index]?.symbol} : &#8377;{fmt.format(Math.round(stocks[index]?.currentPrice * 100) / 100)}
              </span>
              <span className={`stock-change-percent ${stocks[index]?.change > 0 && "increase"} ${stocks[index]?.change < 0 && "decrease"}`}>
                {stocks[index]?.change > 0 && "+"}{fmt.format(Math.round(stocks[index]?.change * 100) / 100)} {`(${fmt.format(Math.round(stocks[index]?.percent * 100) / 100)}%)`}
                {stocks[index]?.change > 0 && <ArrowUpwardIcon className="change-indicator"/>}
                {stocks[index]?.change === 0 && '--'}
                {stocks[index]?.change < 0 && <ArrowDownwardIcon className="change-indicator"/>}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default StockCommodities;
