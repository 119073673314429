import { useState } from "react";
import ImageViewer from "../../comp/ImageHelpers/ImageViewer";
import NoData from "../../comp/message/NoData";
import { Tooltip } from "@mui/material";
import { Check, ContentCopy } from "@mui/icons-material";
import useActivateToast from "../../customHooks/toast/useActivateToast";

const UsersDetails = ({ user }) => {
  const [copyGameEmail, setCopyGameEmail] = useState(false);
  const [copyUsername, setCopyUsername] = useState(false);
  const [copyCompanyMail, setCopyCompanyMail] = useState(false);
  const { activateSneak } = useActivateToast();

  const copyToClipBoard = async (text, event, message) => {
    await navigator.clipboard.writeText(text);
    event(true);
    activateSneak(message, "success");
    setTimeout(() => {
      event(false);
    }, 2000);
  };
  if (!user) {
    return null;
  }
  return (
    <>
      <div className="users-details-card">
        <div className="user-list-card-top">
          <div className="user-avatar">{user && <ImageViewer size={70} user={user} />}</div>
          <span className="user-list-card-line"></span>
          <div className="user-card-top-right">
            <span className="user-card-name">{user?.name}</span>
            <div className="user-card-top-details">
              <span className="user-card-top-heading">USERNAME:</span>
              <span className="user-card-top-value">
                {user?.username}
                {copyUsername ? (
                  <Tooltip title="Copied">
                    <Check />
                  </Tooltip>
                ) : (
                  <Tooltip title="Copy">
                    <ContentCopy onClick={() => copyToClipBoard(user?.username, setCopyUsername, "Username copied to clipboard")} />
                  </Tooltip>
                )}
              </span>
            </div>
            <div className="user-card-top-details">
              <span className="user-card-top-heading">GAME EMAIL:</span>
              <div className="user-card-top-value">
                <span>{user?.gameEmail}</span>
                {copyGameEmail ? (
                  <Tooltip title="Copied">
                    <Check />
                  </Tooltip>
                ) : (
                  <Tooltip title="Copy">
                    <ContentCopy onClick={() => copyToClipBoard(user?.gameEmail, setCopyGameEmail, "Game Email copied to clipboard")} />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        <span className="user-startup-info">STARTUP INFO</span>
        {user?.companyID?._id ? (
          <div className="user-list-card-bottom">
            <div className="user-card-bottom-left">
              <div className="user-startup-details">
                <span className="user-card-headings">Startup Name</span>
                <span className="user-card-values">{user?.companyID?.companyName}</span>
              </div>
              <div className="user-startup-details">
                <span className="user-card-headings">Game Email</span>
                <div className="user-card-values">
                  <span>{user?.companyID?.companyMail}</span>
                  {copyCompanyMail ? (
                    <Tooltip title="Copied">
                      <Check />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Copy">
                      <ContentCopy onClick={() => copyToClipBoard(user?.companyID?.companyMail, setCopyCompanyMail, "Company game email copied to clipboard")} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className="user-card-bottom-right">
              <div className="user-startup-details">
                <span className="user-card-headings">Title</span>
                <span className="user-card-values">{user?.companyID?.founder === user?._id ? `Founder` : `Co-Founder`}</span>
              </div>
              <div className="user-startup-details">
                <span className="user-card-headings">Networth</span>
                <span className="user-card-values">---</span>
              </div>
            </div>
          </div>
        ) : (
          <NoData message="No data found" />
        )}
      </div>
    </>
  );
};

export default UsersDetails;
