import styled from "@emotion/styled";
import { Card, createTheme, TextareaAutosize, TextField } from "@mui/material";

export const CustomPlutocratTextArea = styled(TextareaAutosize)((props) => ({
    borderRadius: "15px",
    width: "calc(100% - 2.4rem) !important",
    backgroundColor: "#170020",
    fontColor: "white",
    color: "#fff",
    fontFamily: "comfortaa",
    fontSize: "0.8rem",
    border: props.briefError ? "1px solid red" : "1px solid #420867",
    padding: "0.7rem",
    outline: "none",
    lineHeight: "1.1rem",
    height: "100%",
    overflow: "auto",
    '&::-webkit-input-placeholder ': {
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "bold",
        fontSize: "0.9rem"
    }
}))

export const CustomPlutocratTextField = styled(TextField)({
    borderRadius: "var(--least-radius)",
    width: "calc(100% - 14px)",
    // margin: "7px",
    backgroundColor: "#170020",
    fontColor: "white",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },

    "& label": {
        // marginTop: "5px",
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "bold",
        fontSize: "14px",
    },
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: "#420867",
        color: "#a1a1a1"
    },
    "& .MuiInputBase-root": {
        // height: 45
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: "#a1a1a1",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
        color: "#d08bfb"
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#420867",
    },

    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "#420867",
        },
        "&:hover fieldset": {
            borderColor: "#420867",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#420867",
        },
    },
})
export const customTheme = createTheme({
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    fontFamily: "Comfortaa",
                    fontSize: "12px",
                    color: "gray",
                    "&.Mui-focused": {
                        fontSize: "16px",
                    },
                },
            },
        },

        MuiBackdrop: {
            styleOverrides: {
                // root: {
                //     backgroundColor: "rgba(0, 0, 0, 0.8) !important",
                // }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Comfortaa",
                    color: "white",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    fontColor: "#7e0fc4",
                    fontSize: "14px",
                    fontFamily: "Comfortaa",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: "#9232cd !important",
                    fontWeight: "100 !important"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    fontFamily: "Comfortaa",
                    color: "white",
                    borderRadius: "25px",
                    width: "100%",
                    backgroundColor: "#7e0fc4",
                    outline: "none",
                    boxShadow: "0px 0px 10px #7e0fc4",
                    "&:hover": {
                        backgroundColor: "#dd33fa",
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderRadius: "10px",
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                    // '&::-webkit-scrollbar-track': {
                    //     marginTop: '0.5rem',
                    //     marginBottom: '0.5rem',
                    // },
                    // '&::-webkit-scrollbar-track:vertical': {
                    //     borderRadius: '1px',
                    //     background: 'darkgrey !important',
                    //     borderRight: '7px darkgrey solid',
                    //     borderLeft: '7px transparent solid !important',
                    //     backgroundClip: 'padding-box !important',
                    // },
                    // '&::-webkit-scrollbar-thumb': {
                    //     background: '#7e0fc4',
                    //     borderRadius: '1px',
                    //     border: 'transparent',
                    //     borderLeft: '7px transparent solid',
                    //     backgroundClip: 'padding-box',
                    // },



                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "20px",
                    backgroundColor: "transparent"
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: "0rem"
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#7e0fc4",
                    },
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: "#9232cd !important"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Comfortaa",
                    color: "white",
                    fontSize: "14px",
                    background: "#1c012c !important",
                    borderBottom: "1px solid #441660",
                    // textTransform: "capitalize",
                    '&:last-child': {
                        borderBottom: "none"
                    },
                    '&:hover': {
                        backgroundColor: '#a02aeb !important',
                        color: 'white'
                    },
                    backgroundColor: "#7e0fc4",
                    "&.Mui-selected": {
                        backgroundColor: "#7e0fc4",
                        "&.Mui-focusVisible": { background: "#7e0fc4" }
                    },

                    "& li.Mui-selected:hover": {
                        background: "#7e0fc4"
                    }
                }
            }
        },
    },
    palette: {
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: "#f44336",
        },
    },
});

export const BankAccountTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                    marginTop: "30px",
                    fontSize: "18px",
                    height: "50px",
                    fontFamily: "Comfortaa",
                    color: "white",
                    borderRadius: "25px",
                    width: "70%",
                    backgroundColor: "#7e0fc4",
                    outline: "none",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#dd33fa",
                    },
                },
            },
        },
    },

    palette: {
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: "#f44336",
        },
    },
});
export const MailCustomTextField = styled(TextField)({
    width: "100%",
    backgroundColor: "transparent",
    fontColor: "#e3e3e3",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },
    "& label": {
        marginTop: "2px",
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "100",
        fontSize: "14px",
    },
    "& .MuiTypography-root": {
        color: "#a8a8a8",
        fontSize: "13px",
        paddingRight: "0.5rem",
        fontFamily: "comfortaa"
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d2d2d26b",
    },
    "& .MuiInput-underline:before": {
        borderBottomColor: "#d2d2d26b",
    },
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: "#420867",
        color: "#a1a1a1"
    },
    '& .MuiInputBase-input': {
        '& .MuiOutlinedInput-input.Mui-disabled': {
            WebkitTextFillColor: "red",
        }
    }
    ,
    "& .MuiInputLabel-root.Mui-disabled": {
        color: "#d08bfb"
    },
    "& .MuiFormHelperText-root": {
        color: "#9232cd"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "red",
        },
        "&:hover fieldset": {
            borderColor: "red",
        },
        "&.Mui-focused fieldset": {
            borderColor: "red",
        },
    },
})
export const CssTextField = styled(TextField)({
    borderRadius: "15px",
    width: "100%",
    backgroundColor: "#170020",
    fontColor: "#e3e3e3",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },


    "& label": {
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "100",
        fontSize: "14px",
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#420867",
    },
    '& .MuiFormHelperText-root.Mui-disabled': {
        color: "#9b9b9b"
    },
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: "#420867",
        color: "#a1a1a1"
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: "#a1a1a1",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
        color: "#d08bfb"
    },
    "& .MuiFormHelperText-root": {
        color: "white"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "#420867",
        },
        "&:hover fieldset": {
            borderColor: "#420867",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#420867",
        },
    },
});

export const CommentCustomTextField = styled(TextField)({
    width: "95%",
    backgroundColor: "transparent",
    fontColor: "#e3e3e3",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },
    "& label": {
        marginTop: "2px",
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "100",
        fontSize: "14px",
    },
    "& .MuiTypography-root": {
        color: "#a8a8a8",
        fontSize: "13px",
        paddingRight: "0.5rem",
        fontFamily: "comfortaa"
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d2d2d26b",
        opacity: ".3",
    },
    "& .MuiInput-underline:before": {
        borderBottomColor: "#d2d2d26b",
        opacity: ".3",
    },
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: "#420867",
        color: "#a1a1a1"
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: "red",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
        color: "#d08bfb"
    },
    "& .MuiFormHelperText-root": {
        color: "white"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "red",
        },
        "&:hover fieldset": {
            borderColor: "red",
        },
        "&.Mui-focused fieldset": {
            borderColor: "red",
        },
    },
})


// to display bank cards
export const BankCardChooseWrap = styled(Card)({
    height: "100%", minHeight: "400px", minWidth: 120,
    marginRight: "10px", marginLeft: "10px", flex: 1,
    backgroundColor: "black", borderRadius: "30px", position: "relative",
    '&:hover': {
        boxShadow: "0px 0px 20px #7e0fc4",
    }
})

export const radioTheme = {
    // color: "#420867",
    fontFamily: "Comfortaa",
    // "&.Mui-checked": {
    //     color: "#420867",
    // },
};


export const startupRadioTheme = {
    color: "#420867",
    fontFamily: "Comfortaa",
    fontSize: "0.7rem",
    "& .MuiSvgIcon-root": {
        height: 15,
        width: 15,
    },
    "& .MuiFormControlLabel-label.Mui-disabled": {
        color: "#432f50"
    },
    "&.Mui-checked": {
        color: "#420867",
    },
    "& .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled": {
        color: "#715889"
    },
    "& .MuiButtonBase-root-MuiRadio-root.Mui-disabled": {
        color: "red"
    }

};

export const searchDefaultInputPropsStyle = {
    color: "#fff",
    fontFamily: "comfortaa",
    fontSize: "0.7rem",
}

export const defaultInputPropsStyle = {
    color: "#fff",
    fontFamily: "comfortaa",
    fontSize: "0.8rem",
}

export const socialMediaInputPropsStyle = {
    color: "#d0b6df",
    fontFamily: "comfortaa",
    fontSize: "0.8rem",
}
export const commentInputPropsStyle = {
    color: "#d0b6df",
    fontFamily: "comfortaa",
    fontSize: "0.8rem",
    marginLeft: "20px",
}
export const marketingNewsPageSelector = {
    fontFamily: "comfortaa",
    fontSize: "1rem",
    borderRadius: "10px",
    border: "1px solid #390659",
}

export const BankRegisterTextfield = styled(TextField)({
    borderRadius: "15px",
    width: "calc(100% - 14px)",
    margin: "7px",
    backgroundColor: "#170020",
    fontColor: "white",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },

    "& label": {
        marginTop: "5px",
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "bold",
        fontSize: "14px",
    },
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: 'transparent',
        borderColor: "#420867",
        color: "#a1a1a1"
    },
    "& .MuiInputBase-root": {
        height: 45
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: "#a1a1a1",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
        color: "#d08bfb"
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#420867",
    },


    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "#420867",
        },
        "&:hover fieldset": {
            borderColor: "#420867",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#420867",
        },
    },
});


export const PlutoSignupTextField = styled(TextField)({
    borderRadius: "15px",
    width: "100%",
    margin: "10px",
    backgroundColor: "#170020",
    fontColor: "white",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "14px",
    },
    "& .MuiInputBase-root": {
        height: 45
    },

    "& label": {
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "bold",
        fontSize: "13px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#420867",
    },

    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "#420867",
        },
        "&:hover fieldset": {
            borderColor: "#420867",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#420867",
        },
    },
})

export const FirstLoginTextField = styled(TextField)({
    borderRadius: "15px",
    width: "100%",
    marginBottom: "10px",
    backgroundColor: "#170020",
    fontColor: "white",

    "& label.Mui-focused": {
        color: "#d08bfb",
        margin: "0px",
        fontSize: "10px",
    },
    "& .MuiInputBase-root": {
        height: 45
    },

    "& label": {
        color: "#d08bfb",
        fontFamily: "comfortaa",
        fontWeight: "bold",
        fontSize: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "&.MuiInputLabel-outlined": {
        margin: "10px",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#420867",
    },

    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
            borderColor: "#420867",
        },
        "&:hover fieldset": {
            borderColor: "#420867",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#420867",
        },
    },
})