import Cookies from "js-cookie"
import { toasts } from "../comp/toast/toast"
import store from "../redux/store"
import { clearGameLoginDetails, resetCompanyMails, resetMails, resetNotifications, resetPersonalMessage } from "../redux/userRedux"
import { removeBankCookies, removeGameCookies, removeStartupBankCookies } from "./removeCookies"
import { bankLogout } from "../MiddleWares/Bank/BankApis"
import { gameLogout } from "../MiddleWares/Auth/AuthApi"

export const removeGameData = async (toastMessage = "Your session expired. Please login again", displayToast = true) => {
    try {
        let refreshToken = Cookies.get('refresh-token');
        let bankRefreshToken = Cookies.get('bank-refresh-token');
        if (bankRefreshToken) {
            await bankLogout({ refreshToken: bankRefreshToken })
        }
        if (refreshToken) {
            await gameLogout({ refreshToken })
        }
        // const stocks = JSON.parse(localStorage.getItem('stocks'));
        // if (stocks) localStorage.removeItem('stocks');
        localStorage.clear()
        store.dispatch(resetNotifications())
        store.dispatch(resetMails())
        store.dispatch(resetCompanyMails())
        store.dispatch(clearGameLoginDetails());
        store.dispatch(resetPersonalMessage());
        removeGameCookies()
        removeBankCookies()
        removeStartupBankCookies()
        if (displayToast) {
            toasts.info(toastMessage);
        }
        return Promise.resolve({ message: "Success" })
    } catch (err) {
        localStorage.clear()
        removeGameCookies()
        removeBankCookies()
        removeStartupBankCookies()
        if (displayToast) {
            toasts.info(toastMessage);
        }
        return Promise.resolve({ message: "Success" })
    }
}

export const removeBankData = async ({logout = "both", displayToast = true, bankID, bankName}) => {
    try {
        if (logout === "personal" || logout === "both") {

            let bankRefreshToken = Cookies.get('bank-refresh-token');
            if (bankRefreshToken) {
                await bankLogout({ refreshToken: bankRefreshToken, bankID, bankName })
            }
            removeBankCookies()
        }

        if (logout === "startup" || logout === "both") {
            let bankStartupRefreshToken = Cookies.get('bank-startup-refresh-token');
            if (bankStartupRefreshToken) {
                await bankLogout({ refreshToken: bankStartupRefreshToken, bankID, bankName })
            }
            removeStartupBankCookies()
        }

        // else{
        //     let bankRefreshToken = Cookies.get('bank-refresh-token');
        //     if (bankRefreshToken) {
        //         await bankLogout({ refreshToken: bankRefreshToken })
        //     }
        //     let bankStartupRefreshToken = Cookies.get('bank-startup-refresh-token');
        //     if (bankStartupRefreshToken) {
        //         await bankLogout({ refreshToken: bankStartupRefreshToken })
        //     }
        //     removeBankCookies()
        //     removeStartupBankCookies()
        // }
        // let bankRefreshToken = Cookies.get('bank-refresh-token');
        // if (bankRefreshToken) {
        //     await bankLogout({ refreshToken: bankRefreshToken })
        // }
        // // store.dispatch(BankApiCalls.endpoints.bankSignOut.initiate({ refreshToken: bankRefreshToken }))
        // removeBankCookies()

        if (displayToast) {
            toasts.info(`Your ${logout} bank session expired. Please login ${logout} again`);
        }
        return Promise.resolve({ message: "Success" })
    } catch (err) {
        if (logout === "personal" || logout === "both")
            removeBankCookies()
        if (logout === "startup" || logout === "both")
            removeStartupBankCookies()
        if (displayToast) {
            toasts.info(`Your ${logout} bank session expired. Please login ${logout} again`);
        }
        return Promise.resolve({ message: "Success" })
    }
}